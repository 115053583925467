import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Table,
  Row,
  Spinner,
} from "reactstrap"
import API from "../../api"
import user1 from "../../assets/images/users/userImage.png"

const DetailEnseignants = props => {
  const [id, setId] = useState("")
  const [nom, setNom] = useState("")
  const [prenom, setPrenom] = useState("")
  const [numero, setNumero] = useState("")
  const [nationnalite, setNationnalite] = useState("")
  const [lieuNaissance, setLieuNaissance] = useState("")
  const [adresse, setAdresse] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [telephone, setTelephone] = useState("")
  const [gender, setGender] = useState("")
  const [typeGender, setTypeGender] = useState("")
  const [grade, setGrade] = useState([])
  const [date, setDate] = useState()
  const [loading, setLoading] = useState(false)
  const [enseignant_id, setEnseignant_id] = useState("")
  const [etat, setEtat] = useState(1)
  const [edit, setEdit] = useState(0);
  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Enseignant")
    );
    setEdit(typeArticleSubMenu.sous_menu[0].edit)
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setId(id)
    setEnseignant_id(id)
    const res = await API.post("getById_enseignant", { id }).then(res => {
      setNom(res.data.Enseignant.firstName)
      setPrenom(res.data.Enseignant.lastName)
      setNumero(res.data.Enseignant.code)
      setGender(res.data.Enseignant.gender)
      if (res.data.Enseignant.gender != 1) {
        setTypeGender("Feminin")
      } else {
        setTypeGender("Masculin")
      }
      setNationnalite(res.data.Enseignant.nationality.label)
      setDate(res.data.Enseignant.birthdate)
      setLieuNaissance(res.data.Enseignant.birthplace)
      setAdresse(res.data.Enseignant.addressStreet)
      setEmail(res.data.Enseignant.email)
      setTelephone(res.data.Enseignant.telephone)
      setMobile(res.data.Enseignant.mobile)
      setGrade(res.data.Enseignant.grade.label)
      setLoading(true)

    })
    const resC = await API.post("check_profile_image", {
      type: "enseignant",
      id: id,
    }).then(resC => {
      setEtat(resC.data.error)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Consultation Enseignant | Cresus </title>
        </MetaTags>
        {loading ? (
          <Container fluid={true}>
            <Row>
              <Col className="pt-4" xl="6">
                <Card className="overflow-hidden">
                  <CardBody className="pt-4">
                    <Row>
                      <Col sm="4">
                        <div
                          style={{ marginTop: "5%" }}
                          className="avatar-md profile-user-wid mb-4"
                        >
                          {etat != 0 ? (
                            <img
                              className="rounded-circle header-profile-user"
                              src={
                                "https://ismsf.scolarite.backcresus-institut.ovh/api/assetsmanager/enseignant/" + id
                              }
                            />
                          ) : (
                            <img
                              className="rounded-circle header-profile-user"
                              src={user1}
                            />
                          )}
                        </div>
                      </Col>
                      <Col sm="8">
                        <div>
                          <Row>
                            <Col style={{ display: "flex" }} xs="12">
                              <p className="text-muted mb-0"> </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ display: "flex" }} xs="12">
                              <i className="fas fa-house-user mb-2 me-2"></i>
                              <p className="text-muted mb-0"> {adresse}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ display: "flex" }} xs="12">
                              <i className="fas fa-mobile mb-2 me-2"></i>
                              <p className="text-muted mb-0">{telephone}</p>
                              <i
                                style={{ marginLeft: "1vw" }}
                                className="fas fa-phone mb-2 me-2"
                              ></i>
                              <p className="text-muted mb-0">{mobile}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ display: "flex" }} xs="12">
                              <i className="fas fa-at mb-2 me-2"></i>
                              <p className="text-muted mb-0">{email}</p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="6" className="pt-4">
                      <Table>
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-user mb-2 me-2"></i>
                              Nom Enseignant
                            </td>
                            <td>{nom}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-user mb-2 me-2"></i>
                              Prénom Enseignant
                            </td>
                            <td>{prenom}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-calendar-alt -bill mb-2 me-2"></i>
                              Date de Naissance
                            </td>
                            <td>{date}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-map-marker -bill mb-2 me-2"></i>
                              Lieu de Naissance
                            </td>
                            <td>{lieuNaissance}</td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                width: "20vw",
                                display: gender === 1 ? "block" : "none",
                              }}
                            >
                              <i className="fas fa-male -bill mb-2 me-2"></i>
                              Sexe
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                width: "20vw",
                                display: gender === 2 ? "block" : "none",
                              }}
                            >
                              <i className="fas fa-female -bill mb-2 me-2"></i>
                              Sexe
                            </td>
                            <td>{typeGender}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col lg="6" className="pt-4">
                      <Table>
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-flag -bill mb-2 me-2"></i>
                              Nationnalité
                            </td>
                            <td>{nationnalite}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-user-graduate mb-2 me-2"></i>
                              Grade Enseignant
                            </td>
                            <td>{grade}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-id-card -bill-wave mb-2 me-2"></i>
                              CIN
                            </td>
                            <td>{numero}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>


                    <div
                      style={{
                        display: "flex",
                        justifyContent: "felx-start",
                      }}
                    >
                      {edit === 1 ? (
                          <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                              className="text-center mt-4"
                          >
                            <Button
                                type="button"
                                color="success"
                                className="btn btn-success mb-2 me-2"
                                onClick={() =>
                                    props.history.push("/EditEnseignant?id=" + id)
                                }
                            >
                              Modifier
                            </Button>
                          </div>
                      ):(null)}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <Link to="/Enseignant">
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning mb-2 me-2 "
                          >
                            Annuler
                          </Button>
                        </Link>
                      </div>
                    </div>


                  </Row>
                </CardBody>
              </Card>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {"Chargement"} ...{" "}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default DetailEnseignants

DetailEnseignants.propTypes = {
  history: PropTypes.object,
}
