import React, {useEffect, useState} from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import DataTableDemandeAdministrativeScolarite from "./DataTableDemandeAdministrativeScolarite";
import AddDemandeAdministrativeScolarite from "./AddDemandeAdministrativeScolarite";

const DemandeAdministrativeScolarite = props => {
  const [section, setSection] = useState(1)
  const [add, setAdd] = useState(0);
  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Gestion demande personnel")
    );
    setAdd(typeArticleSubMenu.sous_menu[0].add)
  }, [])
  const back = () => {
    setSection(1)
  }

  const renderBlock = section => {
    if (section === 1) {
      return <DataTableDemandeAdministrativeScolarite />
    } else if (section === 2) {
      return <AddDemandeAdministrativeScolarite setSection={setSection} back={back} />
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Demande Administrative")} | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
                >
                  {props.t("Demande Administrative")}
                </NavLink>
              </NavItem>
              {add === 1 ?(
                  <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: section === 2,
                        })}
                        onClick={() => {
                          setSection(2)
                        }}
                    >
                      {props.t("Ajouter")} {props.t("Demande Administrative")}
                    </NavLink>
                  </NavItem>
              ):(null)}

            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DemandeAdministrativeScolarite))
DemandeAdministrativeScolarite.propTypes = {
  t: PropTypes.any,
}
