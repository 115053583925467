import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import classnames from "classnames"
import GroupeSemestre from "./GroupeSemestre"

const EnseignantsGroupes = props => {
  const [section, setSection] = useState("")
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1", section: 0 },
    { value: 2, label: "Semestre 2", section: 1 },
  ])

  const renderBlock = section => {
    for (let i = 0; i < semestre.length; i++) {
      if (section === i) {
        return <GroupeSemestre semestre={semestre[i]} />
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Nav className="icon-tab nav-justified">
                {semestre.map((element, index) => (
                  <NavItem key={index}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: section === element.section,
                      })}
                      onClick={() => {
                        setSection(element.section)
                      }}
                    >
                      <span className="d-none d-sm-block">{element.label}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              {renderBlock(section)}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EnseignantsGroupes
EnseignantsGroupes.propTypes = {
  history: PropTypes.object,
}
