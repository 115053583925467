import React, { useEffect, useState } from "react"
import PropTypes, { array } from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Button,
  Input,
  ModalHeader,
  Modal,
  ModalBody,
  Form,
  Label,
} from "reactstrap"
import { withRouter } from "react-router"
import Select from "react-select"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Switch from "react-switch"
const DataTableEnseignant = props => {
  const [loading, setLoading] = useState(false)
  const [arraySelect, setArraySelect] = useState([])
  const [arraySelectAll, setArraySelectAll] = useState([])
  const [actions, setActions] = useState([
    { value: 1, label: "Générer Compte" },
    { value: 2, label: "Suppression" },
  ])
  const [selectAction, setSelectAction] = useState("")
  const [orders, setOrders] = useState([])
  const [modal1, setModal1] = useState(false)
  const [etudiantId, setEtudiantId] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  const [delet, setDelet] = useState(0);
  const [imp, setImp] = useState(0);
  const [view, setView] = useState(0);
  const [add, setAdd] = useState(0);
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    sizePerPage: 30,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
      <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "cin",
      text: "CIN",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "nom",
      text: "Nom et prénom",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "grade",
      text: "Grade",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "chef_departement_name",
      text: "Chef departement",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },

    {
      dataField: "Groupes",
      isDummyField: true,
      text: "Groupes",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          view === 1 ? (
              <div style={{display: "flex", justifyContent: "center"}}>
                <Link to={"/EnseignantsGroupes?id=" + row.id}>
                  <i
                      style={{color: "darkmagenta", cursor: "pointer"}}
                      className="fas fa-users-cog"
                  ></i>
                </Link>
              </div>
          ) : (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#A7A3A3", cursor: "pointer"}}
                    className="fas fa-users-cog"
                    title="Vous n'avez pas le droit "
                ></i>
              </div>
          )
      ),
    },
    {
      dataField: "Fiche Voeux",
      isDummyField: true,
      text: "Fiche Voeux",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
          view === 1 ? (
              row.count != 0 ? (
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <Link to={"/FicheVoeux?id=" + row.id}>
                      <i
                          style={{color: "darkorange", cursor: "pointer"}}
                          className="fas fa-clipboard-list"
                      ></i>
                    </Link>
                  </div>
              ) : (
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <i
                        style={{color: "#A7A3A3", cursor: "pointer"}}
                        className="fas fa-clipboard-list"
                        title="Aucun fiche de voeux"
                    ></i>
                  </div>
              )
          ) : (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#A7A3A3", cursor: "pointer"}}
                    className="fas fa-clipboard-list"
                    title="Vous n'avez pas le droit de fiche de voeux"
                ></i>
              </div>
          )
    },
    {
      dataField: "Exploration",
      isDummyField: true,
      text: "Exploration",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          view === 1 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Link to={"/DetailEnseignants?id=" + row.id}>
                  <i
                      style={{ color: "darkcyan", cursor: "pointer" }}
                      className="fas fa-eye"
                  ></i>
                </Link>
              </div>
          ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                    style={{ color: "#A7A3A3", cursor: "pointer" }}
                    className="fas fa-eye"
                    title="Vous n'avez pas le droit de fiche de voeux"
                ></i>
              </div>
          )
      ),
    },
    {
      dataField: "Compte",
      isDummyField: true,
      text: "Compte",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
          delet === 1 ? (
          row.is_compte == -1 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                    style={{ color: "blueviolet", cursor: "pointer" }}
                    className="fas fa-user-plus"
                    onClick={() => addAccount(row)}
                ></i>
              </div>
          ) : row.is_compte == 1 && row.active_compte == 0 ? (
              <div
                  onClick={() => toggle1(row)}
                  style={{ display: "flex", justifyContent: "center" }}
              >
                <i
                    style={{ color: "red", cursor: "pointer" }}
                    className="fas fa-user"
                ></i>
              </div>
          ) : row.is_compte == 1 && row.active_compte == -1 ? (
              <div
                  onClick={() => addAccount(row)}
                  style={{ display: "flex", justifyContent: "center" }}
              >
                <i
                    style={{ color: "blueviolet", cursor: "pointer" }}
                    className="fas fa-user"
                ></i>
              </div>
          ):(
              <div
                  onClick={() => toggle1(row)}
                  style={{ display: "flex", justifyContent: "center" }}
              >
                <i
                    style={{ color: "#34c38f", cursor: "pointer" }}
                    className="fas fa-user"
                ></i>
              </div>
          )
          ):(
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                    style={{ color: "#A7A3A3", cursor: "pointer" }}
                    className="fas fa-user"
                    title="Vous n'avez pas le droit de fiche de voeux"
                ></i>
              </div>
          )
    },
    {
      dataField: "active",
      isDummyField: true,
      text: "Etat",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (colIndex, row) => (
          add === 1 ? (
          <Switch
              uncheckedIcon={<Offsymbol />}
              checkedIcon={<OnSymbol />}
              className="me-1 mb-sm-8 mb-2"
              onColor="#34c38f"
              onChange={() => activeEnseignant(row)}
              checked={row.active}
          />
          ):(
              <Switch
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  className="me-1 mb-sm-8 mb-2"
                  onColor="#34c38f"
                  onChange={() => activeEnseignant(row)}
                  checked={row.active}
                  disabled={true}
              />
          )
      ),
    },
  ]
  const Offsymbol = () => {
    return (
        <div
            title="Activé"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2,
            }}
        >
          {" "}
          A
        </div>
    )
  }
  const OnSymbol =  props => {
    return (
        <div
            title="Désactivé"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2,
            }}
        >
          {" "}
          D
        </div>
    )
  }
  const { SearchBar } = Search
  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Enseignant")
    );
    setDelet(typeArticleSubMenu.sous_menu[0].delete)
    setImp(typeArticleSubMenu.sous_menu[0].imp)
    setView(typeArticleSubMenu.sous_menu[0].view)
    setAdd(typeArticleSubMenu.sous_menu[0].add)
      const res = API.post("enseignant/list_enseignant", {
        annee_id:annee_id
      }).then(res => {
        setOrders(res.data.test)
        setLoading(true)
      })
  }, [])
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        var array = arraySelect
        array.push(row)
        setArraySelect(array)
        let tab = [...arraySelectAll]
        tab.push(row)
        setArraySelectAll(tab)
      } else {
        var array = arraySelect
        array.splice(rowIndex, 1)
        setArraySelect(array)
        if (arraySelectAll.length != 0) {
          let tab = [...arraySelectAll]
          let etudId = row.id
          for (let index = 0; index < tab.length; index++) {
            let idEtud = tab[index].id
            if (idEtud == etudId) {
              tab.splice(index, 1)
              setArraySelectAll(tab)
              break
            }
          }
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        var array = arraySelectAll
        for (let i = 0; i < orders.length; i++) {
          array.push(orders[i])
        }
        setArraySelectAll(array)
      } else {
        setArraySelectAll([])
      }
    },
  }
  const action = async () => {
    setLoading(true)
    if (selectAction.value == 1) {
      if (arraySelectAll.length != 0) {
        var enseignants = arraySelectAll
      } else {
        var enseignants = arraySelect
      }
      const res = await API.post("user/generate_accounts", {
        enseignants: enseignants,
      }).then(res => {
        toast.success(" Les Comptes Générés Successfully ", {
          containerId: "A",
        })
        const resE = API.get("user/list_id_enseignant").then(resE => {
          const res = API.post("enseignant/list", {
            comptes: resE.data.comptes,
          }).then(res => {
            setOrders(res.data.enseignants)
          })
        })
      })
    } else if (selectAction.value == 2) {
      if (arraySelectAll.length != 0) {
        var enseignants = arraySelectAll
      } else {
        var enseignants = arraySelect
      }
      const resD = await API.post("enseignant/deletes", {
        enseignants: enseignants,
      }).then(resD => {
        const resDA = API.post("user/delete_accounts", {
          enseignants: resD.data.enseignants,
        }).then(resDA => {
          toast.success("Les Comptes Supprimés Successfully ", {
            containerId: "A",
          })
          const resE = API.get("user/list_id_enseignant").then(resE => {
            const res = API.post("enseignant/list", {
              comptes: resE.data.comptes,
            }).then(res => {
              setOrders(res.data.enseignants)
              setLoading(true)
            })
          })
        })
      })
    }
  }
  const activeEnseignant = async row => {
    var active = row.active
    var etudiantId = row.id
    if (active == 0) {
      var active = 1
      const res = await API.post("enseignant/activer_enseignant", {
        id: etudiantId,
        active: active,
      }).then(res => {
        toast.success(" 🔓✔️ Enseignant Activé Avec succès", {
          containerId: "A",
        })
         API.post("enseignant/list_enseignant", {
          annee_id:annee_id
        }).then(res => {
          setOrders(res.data.test)
          setLoading(true)
        })
      })
    } else if (active == 1) {
      var active = 0
      const res = await API.post("enseignant/activer_enseignant", {
        id: etudiantId,
        active: active,
      }).then(res => {
        toast.success(" 🔒❌ Enseignant Désactivé Avec succès ", {
          containerId: "A",
        })
        API.post("enseignant/list_enseignant", {
          annee_id:annee_id
        }).then(res => {
          setOrders(res.data.test)
          setLoading(true)
        })
      })
    }
  }
  const addAccount = async row => {
    const res = await API.post("enseignant/activer_enseignant", {
      id: row.id,
      active: 1,
    }).then(res => {
      toast.success(" 🔒❌ Enseignant Désactivé Avec succès ", {
        containerId: "A",
      })
      API.post("enseignant/list_enseignant", {
        annee_id:annee_id
      }).then(res => {
        setOrders(res.data.test)
        setLoading(true)
      })
    })
  }
  const toggle2 = async row => {
    setModal1(!modal1)
  }
  const toggle1 = async row => {
    setModal1(!modal1)
    setName(row.nom)
    setEmail(row.email)
    setEtudiantId(row)
  }
  const edit = async etudiantId => {
    var id = etudiantId.id
    if(password !=""){
      const res = await API.post("enseignant/update_password_email", {
        id: id,
        email: email,
        password: password,
      }).then(res => {
        setModal1(false)
      })
    }else{
      setModal1(true)
      toast.error("⛔ Mot de passe est obligatoires", {
        containerId: "A",
      })
    }
  }
  const activate = async etudiantId => {
    var id = etudiantId.id
    var active = etudiantId.active_compte
    if (active == 0) {
      var active = 1
      const res = await API.post("enseignant/activer_enseignant", {
        id: id,
        active: active,
      }).then(res => {
        setModal1(false)
        toast.success(" 🔓✔️ Compte Activé Successfully", {
          containerId: "A",
        })
        API.post("enseignant/list_enseignant", {
          annee_id:annee_id
        }).then(res => {
          setOrders(res.data.test)
          setLoading(true)
        })
      })
    } else if (active == 1) {
      var active = 0
      const res = await API.post("enseignant/activer_enseignant", {
        id: id,
        active: active,
      }).then(res => {
        setModal1(false)
        toast.success(" 🔒❌ Compte Désactivé Successfully", {
          containerId: "A",
        })
        API.post("enseignant/list_enseignant", {
          annee_id:annee_id
        }).then(res => {
          setOrders(res.data.test)
          setLoading(true)
        })
      })
    }
  }
  return (
      <React.Fragment>
        <div>
          {loading ? (
              <Row className="mt-4">
                <Col xs="12">
                  <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={Columns()}
                      search
                      bootstrap4
                  >
                    {toolkitProps => (
                        <div>
                          <div>
                            <div className="mb-3 row" style={{ display: "flex" }}>
                              {delet === 1 ? (
                                  <div className="col-md-6 mb-3 row">
                                    <div className="col-md-8 select-action">
                                      <Select
                                          options={actions}
                                          isSearchable={true}
                                          onChange={e => setSelectAction(e)}
                                          placeholder="Actions"
                                      />
                                    </div>
                                    <div className="col-md-4 appliquer-button">
                                      <Button
                                          type="button"
                                          color="primary"
                                          className="btn btn-primary"
                                          onClick={action}
                                      >
                                        Appliquer
                                      </Button>
                                    </div>
                                  </div>
                              ):(null)}
                              <div className="col-md-6 mb-3 row">
                                <div className="col-md-8 search-box-etd">
                                  <div
                                      className="search-box me-2 mb-2 d-inline-block"
                                      style={{ width: "100%" }}
                                  >
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {imp === 1 ? (
                                  <div className="col-md-4 appliquer-button">
                                    <a
                                        href="https://ismsf.scolarite.backcresus-institut.ovh/api/impression_list_enseignants"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                      <button className="btn btn-primary btn btn-primary">
                                        <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                        Liste Enseignant
                                      </button>
                                    </a>
                                  </div>
                              ):(null)}
                            </div>
                          </div>
                          <BootstrapTable
                              wrapperClasses="table-responsive"
                              noDataIndication={() => <NoDataIndication />}
                              striped={false}
                              bordered={false}
                              selectRow={selectRow}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"table-light"}
                              hover
                              pagination={paginationFactory(pageOptions)}
                              {...toolkitProps.baseProps}
                          />
                        </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
          ) : (
              <div>
                <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                >
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                >
                  {" "}
                  En cours ...
                </h4>
              </div>
          )}
        </div>
        <Modal isOpen={modal1} toggle={toggle2} centered={true}>
          <ModalHeader
              style={{ width: "100% !important", textAlign: "center !important" }}
              toggle={toggle2}
              tag="h4"
          >
            {name}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Form>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Email</Label>
                      <Input
                          lg="3"
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Password</Label>
                      <Input
                          lg="3"
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
              <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
              >
                {" "}
                <div lg="4">
                  <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      className="text-center mt-4"
                  >
                    {etudiantId.active_compte == 0 ? (
                        <Button
                            type="button"
                            color="success"
                            className="btn btn-primary  mb-2 me-2"
                            onClick={() => activate(etudiantId)}
                        >
                          Activer
                        </Button>
                    ) : (
                        <Button
                            type="button"
                            color="danger"
                            className="btn btn-primary  mb-2 me-2"
                            onClick={() => activate(etudiantId)}
                        >
                          Desactiver
                        </Button>
                    )}
                  </div>
                </div>
                <di lg="4">
                  <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      className="text-center mt-4"
                  >
                    <Button
                        type="button"
                        color="warning"
                        className="btn btn-warning  mb-2 me-2"
                        onClick={toggle2}
                    >
                      Annuler
                    </Button>
                  </div>
                </di>
                <div lg="4">
                  <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      className="text-center mt-4"
                  >
                    <button
                        type="button"
                        className="btn btn-primary "
                        onClick={() => edit(etudiantId)}
                    >
                      Confirmer
                    </button>
                  </div>
                </div>
              </div>
            </Row>
          </ModalBody>
        </Modal>
        <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
        />
      </React.Fragment>
  )
}

export default withRouter(DataTableEnseignant)
DataTableEnseignant.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
}
