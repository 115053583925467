import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {Row, Col, Spinner, Modal, ModalHeader, ModalBody, CardTitle, Form, Label} from "reactstrap"
import { withRouter } from "react-router"
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";
import {Flip, toast, ToastContainer} from "react-toastify";
import APIS from "../../api";

const DataTableHistoriqueEtudiant = props => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [etudiant, setEtudiant] = useState([]);
  const [selectEtudiant, setSelectEtudiant] = useState("");
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  const NoDataIndication = () => (
      <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "etudiant.etudiant_name",
      text: "Enseignant",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "created_at_formatted",
      text: "Date / Heure",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
  ]
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    alwaysShowAllBtns: true,
    sizePerPage: 20,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }
  const { SearchBar } = Search

  useEffect(async () => {
    const res = await API.post("etudiant/get_all_historique_emploi_etud",{annee_id}).then(res => {
      setOrders(res.data.historiques)
      setLoading(true)
    })
    const resEtd = await API.post("select_etudiant",{annee_id}).then(resEtd => {
      setEtudiant(resEtd.data.Etudiant)
    })
  }, [])


  const search = async () => {
    setOrders([])
    var etudiant_id = selectEtudiant != "" && selectEtudiant!= null  ? selectEtudiant.value : 0
    if(selectEtudiant == 0){
      setLoading(true)
      toast.error("⛔ Enseignant obligatoire pour la recherche !", {
        containerId: "A",
      })
    }else{
      const res = await API.post("etudiant/historique_emploi_etud", {
        etudiant_id: etudiant_id,
        annee_id,
      }).then(res => {
        setOrders(res.data.historiques)
      })
    }
  }
  const searchAll = async () => {
    setSelectEtudiant("")
    const res = await API.post("etudiant/get_all_historique_emploi_etud",{annee_id}).then(res => {
      setOrders(res.data.historiques)
      setLoading(true)
    })
  }
  return (
      <React.Fragment>
        <div>
          <CardTitle style={{ color: "#556ee6" }} className="h4">
            Critéres de Recherches
          </CardTitle>
          <Form className="mt-4">
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-lastname-input2">Etudiant :</Label>
                  <Select
                      options={etudiant}
                      isSearchable={true}
                      isClearable={true}
                      value={selectEtudiant }
                      onChange={e => setSelectEtudiant(e)}
                  />
                </div>
              </Col>
            </Row>
          </Form>
          <div
              className="mb-6"
              style={{ display: "flex", justifyContent: "space-around" }}
          >
            {" "}
            <Col lg="6">
              <div className="text-center mt-4">
                <button
                    type="button"
                    style={{ backgroundColor: "#761C19", borderColor: "#761C19" }}
                    className="btn btn-success mb-2 me-2"
                    onClick={search}
                >
                  Rechercher
                </button>
              </div>
            </Col>

            <Col lg="6">
              <div className="text-center mt-4">
                <button
                    type="button"
                    style={{ backgroundColor: "#761C19", borderColor: "#761C19" }}
                    className="btn btn-success mb-2 me-2"
                    onClick={searchAll}
                >
                  Afficher Tout
                </button>
              </div>
            </Col>
          </div>

          {loading ? (
              <Row>
                <Col xs="12">
                  <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={Columns()}
                      search
                      bootstrap4
                  >
                    {toolkitProps => (
                        <div>
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <BootstrapTable
                              wrapperClasses="table-responsive"
                              noDataIndication={() => <NoDataIndication />}
                              striped={false}
                              bordered={false}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"table-light"}
                              hover
                              pagination={paginationFactory(pageOptions)}
                              {...toolkitProps.baseProps}
                          />
                        </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
          ) : (
              <div>
                <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                >
                  {" "}
                  En cours ...{" "}
                </h4>
              </div>
          )}
          <ToastContainer
              transition={Flip}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
          />
        </div>
      </React.Fragment>
  )
}

export default withRouter(DataTableHistoriqueEtudiant)
DataTableHistoriqueEtudiant.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
}
