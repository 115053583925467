import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../api"
import { withTranslation } from "react-i18next"
import Select from "react-select"

const AffectationChefDepartement = props => {
  const [departement, setDepartement] = useState([])
  const [selectDepartement, setSelectDepartement] = useState("")
  const [enseignant, setEnseignant] = useState([])
  const [selectEnseignant, setSelectEnseignant] = useState("")
  const [specialite, setSpecialite] = useState([]);
  const [selectSpecialite, setSelectSpecialite] = useState("");
  //
  useEffect(async () => {
    //   Département
    const res = await API.get("departement/select").then(res => {
      setDepartement(res.data.Departement)
    })
    //    Enseignant
    const resE = await API.get("enseignant/select").then(resE => {
      setEnseignant(resE.data.select_enseignant)
    })

  }, [])

  const save = async () => {
    if (selectDepartement == "") {
      toast.error("⛔ Département obligatoire", {
        containerId: "A",
      })
    } else  if (selectSpecialite == "") {
      toast.error("⛔ Spécialite obligatoire", {
        containerId: "A",
      })
    } else if (selectEnseignant != "") {
      var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
      var annee_id = userAuthScolarite.user.annee_id
      const res = await API.post("departement/ensignant/add", {
        departement_id: selectDepartement.value,
        enseignant_id: selectEnseignant.value,
        specialite_id:selectSpecialite.value,
        annee_id:annee_id
      })
          .then(res => {
            if(res.data.errNum == 422){
              toast.error("⛔  Chef département est déjà existe !", {
                containerId: "A",
              })
            }else{
              props.setSection(3)
            }
          })
          .catch(() => {
            toast.error("⛔ Problème lors de l'insertion !", {
              containerId: "A",
            })
          })
    } else {
      toast.error("⛔ chef département obligatoire", {
        containerId: "A",
      })
    }
  }
  const getSpecialite = async event => {
    setSelectDepartement(event)
    setSelectSpecialite("")
    setSelectEnseignant("")
    const res = await API.post("specialite/select_by_departement_id", {
      departement_id: event.value,
    }).then(res => {
      setSpecialite(res.data.Specialite)
    })
    const resEnseig = await API.post("enseignant/select_by_departement_id", {
      departement_id: event.value,
    }).then(resEnseig => {
      setEnseignant(resEnseig.data.Enseignant)
    })
  }
  return (
      <React.Fragment>
        <Row>
          <Form>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Département")} :
                  </Label>
                  <Select
                      options={departement}
                      isSearchable={true}
                      onChange={e => getSpecialite(e)}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Spécialite")} :
                  </Label>
                  <Select
                      value={selectSpecialite}
                      options={specialite}
                      isSearchable={true}
                      onChange={e => setSelectSpecialite(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>

              <Col md={6}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    {props.t("Chef département")} :
                  </Label>
                  <Select
                      value={selectEnseignant}
                      options={enseignant}
                      isSearchable={true}
                      onChange={e => setSelectEnseignant(e)}
                  />
                </div>
              </Col>
            </Row>
          </Form>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Col lg="6">
              <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
              >
                <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={props.back}
                >
                  {props.t("Annuler")}
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
              >
                <button type="button" className="btn btn-primary " onClick={save}>
                  {props.t("Confirmer")}
                </button>
              </div>
            </Col>
          </div>
        </Row>
        <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
        />
      </React.Fragment>
  )
}

export default withTranslation()(AffectationChefDepartement)
AffectationChefDepartement.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
