import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {Button, Col, Row, Spinner} from "reactstrap"
import API from "../../api"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {Slide, toast, ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import FileDownload from "js-file-download";


const EmploiTemps = props => {
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [excel, setExcel] = useState(0);
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    sizePerPage: 50,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
      <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "etudiant",
      text: "Etudiant",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "enseigant",
      text: "Enseignant",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "type_instrument",
      text: "Type Instrument",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "jour",
      text: "Jour",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "salle",
      text: "Salle",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "matiere",
      text: "Matière",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "heure",
      text: "Heure",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    }
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Emploi Etudiants")
    );
    setExcel(typeArticleSubMenu.sous_menu[0].exel)
    API.post("seance/list_matiere_par_etudiant", {
      semestre_id: props.semestre_value,annee_id
    }).then(res => {
      setOrders(res.data.SeanceDate)
    })
    setLoading(true)
  }, [props.semestre_value])
  const upload = async () => {
    const res = await API.post(
        "seance/export_matiere_par_etudiant",
        {
          semestre_id: props.semestre_value,annee_id
        },
        { responseType: "blob" }
    ).then(res => {
      FileDownload(res.data, "Emploi etudiant.xlsx")
    })
  }
  return (
      <React.Fragment>
        <div>
          {loading ? (
              <Row className="mt-4">
                <Col xs="12">
                  <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={Columns()}
                      search
                      bootstrap4
                  >
                    {toolkitProps => (
                        <div>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            {excel === 1 ? (
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                        type="button"
                                        color="success"
                                        className="btn  mb-2 me-2"
                                        onClick={upload}
                                    >
                                      <i className="fas fa-file-export me-1" />
                                      Export Etudiant
                                    </Button>
                                  </div>
                                </Col>
                            ):(null)}

                          </Row>
                          <BootstrapTable
                              wrapperClasses="table-responsive"
                              noDataIndication={() => <NoDataIndication />}
                              striped={false}
                              bordered={false}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"table-light"}
                              hover
                              pagination={paginationFactory(pageOptions)}
                              {...toolkitProps.baseProps}
                          />
                        </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
          ) : (
              <div>
                <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                >
                  {" "}
                  En cours ...{" "}
                </h4>
              </div>
          )}
        </div>
        <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
        />
      </React.Fragment>
  )
}

export default EmploiTemps
EmploiTemps.propTypes = {
  history: PropTypes.object,
  semestre_value: PropTypes.object,
  semestre_label: PropTypes.object,
}
