import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import SimpleBar from "simplebar-react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
const SidebarContent = props => {
  const ref = useRef()
  const [userdata, setUserdata] = useState([])
  useEffect(() => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    setUserdata(userAuth);
    ref.current.recalculate();
  }, [])
  const [menuStates, setMenuStates] = useState(new Array(userdata.length).fill(false));
  const toggleMenu = (index) => {
    const updatedMenuStates = [...menuStates];
    updatedMenuStates[index] = !updatedMenuStates[index];
    setMenuStates(updatedMenuStates);
  };
  return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/dashboard" className="">
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
              {userdata.map((menuData, index) => (
                  menuData.sous_menu.length > 1 ? (
                      <li key={index} className={menuStates[index] ? 'open-li' : ''}>
                        <a
                            className={`has-arrow ${menuStates[index] ? 'open' : ''}`}
                            onClick={() => toggleMenu(index)}
                        >
                          <i className={menuData.icon}></i>
                          <span>{menuData.menu}</span>
                        </a>
                        {menuStates[index] && (
                            <ul key={index} className={`sub-menu ${menuStates[index] ? 'open-ul' : ''}`} aria-expanded="true">
                              {menuData.sous_menu.map((subMenuData, subIndex) => (
                                  <li key={subIndex}>
                                    <Link to={subMenuData.url}>
                                      <span>{subMenuData.sous_menu}</span>
                                    </Link>
                                  </li>
                              ))}
                            </ul>
                        )}
                      </li>
                  ) : (
                      <li key={index}>
                        <Link to={menuData.sous_menu[0].url} className="">
                          <i className={menuData.icon}></i>
                          <span>{menuData.menu}</span>
                        </Link>
                      </li>
                  )
              ))}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
  )
}
SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
