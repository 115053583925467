import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import Select from "react-select"
//i18n
import { withTranslation } from "react-i18next"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import API from "../../api"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
import getDay from "date-fns/getDay"

const AddAvisTest = props => {
  const [date, setDate] = useState(null)
  const [quart, setQuart] = useState([])
  const [selectQuart, setSelectQuart] = useState("")
  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState([])
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [jourId, setJourId] = useState("")
  const [typeExamen, setTypeExamen] = useState([])
  const [selectTypeExamen, setSelectTypeExamen] = useState("")
  const [salle, setSalle] = useState([])
  const [selectSalle, setSelectSalle] = useState("")
  const [enseignant, setEnseignant] = useState([])
  const [selectEnseignant, setSelectEnseignant] = useState("")
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [nbrHeureSeance, setNbrHeureSeance] = useState([
    { value: 2, label: "1h" },
    { value: 3, label: "1h:30" },
    { value: 4, label: "2h" },
    { value: 5, label: "2h:30" },
    { value: 6, label: "3h" },
  ])
  const [selectNbrHeureSeance, setSelectNbrHeureSeance] = useState("");
  const [selectSemestre, setSelectSemestre] = useState("")
  const [tousEtudiant, setTousEtudiant] = useState(1)
  const [selectDisabled, setSelectDisabled] = useState(true);
  const [etudiant, setEtudiant] = useState([]);
  const [selectEtudiant, setSelectEtudiant] = useState("");
  const [disbutReg, setDisbutReg] = useState(true)
  var userAuthScolarite = JSON.parse(
      localStorage.getItem("userAuthScolarite")
  )
  var annee_id = userAuthScolarite.user.annee_id
  useEffect(async () => {
    const res = await API.get("enseignant/select").then(res => {
      setEnseignant(res.data.select_enseignant)
    })

    const resQ = await API.get("quart/select_debut").then(resQ => {
      setQuart(resQ.data.Quart)
    })
    const resT = await API.get("type_examen/select").then(resT => {
      setTypeExamen(resT.data.Type_examen)
    })
  }, [])

  const isWeekday = date => {
    const day = getDay(date)
    return day !== 0
  }

  const getQuart = e => {
    setSelectQuart(e)
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
  }

  const getDay1 = async e => {
    setDate(e)
    setSelectQuart("")
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
    // convert date début
    let date = e
    let month = "" + (date.getMonth() + 1)
    let day = "" + date.getDate()
    let year = date.getFullYear()
    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day
    let convertDate = [year, month, day].join("-")
    const res = await API.post("quart/get_date", {
      date: convertDate,
    }).then(res => {
      setJourId(res.data.Date)
    })
  }

  const getMatiere = async e => {
    setSelectType(e)
    setSelectMatiere("")
    setSelectGroupe("")
    const res = await API.post("emploi/get_matiere", {
      type: e.value,
      enseignant_id: selectEnseignant.value,
      annee_id,
      semestre_id:selectSemestre.value
    }).then(res => {
      setMatiere(res.data.matieres)
    })
  }

  const getGroupe = async e => {
    setSelectMatiere(e)
    setSelectGroupe("")
    const resType = await API.post("seance/get_type_enseignement", {
      matiere_id: e.value,
    }).then(resType => {
      if(resType.data.type == 2){
        setTousEtudiant(2)
        setSelectDisabled(false);
      }else{
        setTousEtudiant(1)
        setSelectDisabled(true);
      }
    })
    const resG = await API.post("emploi/get_group", {
      quart_id: selectQuart.value,
      jour_id: jourId,
      quan: 0,
      matiere_id: e.value,
      type: selectType.value,
      enseignant_id: selectEnseignant.value,
      annee_id,
      semestre_id:selectSemestre.value,
      nb_heure_seance:selectNbrHeureSeance.value
    }).then(resG => {
      setGroupe(resG.data.groups)
    })
  }

  const getType = async e => {
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
    setTousEtudiant(1)
    setSelectDisabled(true);
    setSelectEtudiant("")
    setSelectSalle("")
    let enseignant_id = e.value
    setSelectEnseignant(e)
    const res = await API.post("emploi/get_type_matiere", {
      enseignant_id: enseignant_id,annee_id,
      semestre_id:selectSemestre.value
    }).then(res => {
      setType(res.data.emploi)
    })
  }

  const getSalle = async e => {
    setSelectGroupe(e)
    setSelectEtudiant("")
    setEtudiant([])
    const resS = await API.post("emploi/get_salle", {
      quart_id: selectQuart.value,
      jour_id: jourId,
      quan: 0,
    }).then(resS => {
      setSalle(resS.data.salles)
    })

    API.post("seance/list_etudiant_by_group_type_id_avis_rattrapage", {
      matiere_id: selectMatiere.value,
      group_id: [e],
      type: selectType.value,
      quart_id: selectQuart.value,
      jour_id: jourId,
    }).then(resS => {
      setEtudiant(resS.data.Etudiant)
    })
  }

  const save = async () => {
    if (selectSemestre == "") {
      setDisbutReg(true)
      toast.error("⛔ Semestre obligatoire !", {
        containerId: "A",
      })
    } else if (selectTypeExamen == "") {
      setDisbutReg(true)
      toast.error("⛔ Type examen obligatoire !", {
        containerId: "A",
      })
    } else if (date == null) {
      setDisbutReg(true)
      toast.error("⛔ Date obligatoire !", {
        containerId: "A",
      })
    } else if (selectQuart == "") {
      setDisbutReg(true)
      toast.error("⛔ Quart obligatoire !", {
        containerId: "A",
      })
    } else if (selectNbrHeureSeance == "") {
      setDisbutReg(true)
      toast.error("⛔ Nbr heure seance obligatoire !", {
        containerId: "A",
      })
    } else if (selectEnseignant == "") {
      setDisbutReg(true)
      toast.error("⛔ Enseignant obligatoire !", {
        containerId: "A",
      })
    } else if (selectType == "") {
      setDisbutReg(true)
      toast.error("⛔ Type matière obligatoire !", {
        containerId: "A",
      })
    } else if (selectMatiere == "") {
      setDisbutReg(true)
      toast.error("⛔ Matiere obligatoire !", {
        containerId: "A",
      })
    } else if (selectGroupe == "") {
      setDisbutReg(true)
      toast.error("⛔ Groupe obligatoire !", {
        containerId: "A",
      })
    } else if (selectSalle != "") {
      // convert date début
      let dateRatt = date
      let month = "" + (dateRatt.getMonth() + 1)
      let day = "" + dateRatt.getDate()
      let year = dateRatt.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      let convertDate = [year, month, day].join("-")
      //
      if(selectEtudiant != ""){
        if(tousEtudiant == 2){
          var etudiant_id=selectEtudiant.value
        }else{
          var etudiant_id=selectEtudiant
        }
      }
      else{
        var etudiant_id=null
      }
      const res = await API.post("avis/examen/add_scol", {
        date: convertDate,
        jour_id: jourId,
        quart_id: selectQuart.value,
        matiere_id: selectMatiere.value,
        group_id: selectGroupe.value,
        type_matiere: selectType.value,
        type_examen_id: selectTypeExamen.value,
        enseignant_id: selectEnseignant.value,
        semestre_id:selectSemestre.value,
        type_seance:tousEtudiant,
        salle_id: selectSalle.value,
        etudiant_id:etudiant_id,
        annee_id,
        nb_heure_seance:selectNbrHeureSeance.value
      })
        .then(res => {
          props.setSection(1)
        })
        .catch(() => {
          toast.error("Problème lors de l'insertion !", {
            containerId: "A",
          })
        })
    } else {
      setDisbutReg(true)
      toast.error("⛔ Salle obligatoire !", {
        containerId: "A",
      })
    }
  }
  const TousEtudiant = async () => {
    setTousEtudiant(1)
    setSelectGroupe("")
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    setSelectGroupe("")
    setSelectDisabled(true);
    const resG = await API.post("emploi/get_group", {
      semestre_id: semestre_id,
      quart_id: quartId,
      jour_id: jourId,
      quan: etatQuanzaine,
      matiere_id: selectMatiere.value,
      type: selectType.value,
      enseignant_id: enseignantId,
      annee_id:annee_id,
      nb_heure_seance:selectNbrHeureSeance.value
    }).then(resG => {
      setGroupe(resG.data.groups)
    })
  }
  const filterEtudiant = async () => {
    setSelectGroupe("")
    setTousEtudiant(2)
    setSelectDisabled(false);

  }
  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Semestre")}
                </Label>
                <Select
                    options={semestre}
                    isSearchable={true}
                    value={selectSemestre}
                    onChange={e => setSelectSemestre(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Type examen")}
                </Label>
                <Select
                    options={typeExamen}
                    isSearchable={true}
                    value={selectTypeExamen}
                    onChange={e => setSelectTypeExamen(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>

            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Date")}
                  <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                </Label>
                <DatePicker
                  selected={date}
                  filterDate={isWeekday}
                  className="form-control ddate"
                  dateFormat="dd/MM/yyyy"
                  onChange={e => getDay1(e)}
                />
              </div>
            </Col>

            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("À partir de")}
                </Label>
                <Select
                    options={quart}
                    isSearchable={true}
                    value={selectQuart}
                    onChange={e => getQuart(e)}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Nbr heure seance</Label>
                <Select
                    options={nbrHeureSeance}
                    isSearchable={true}
                    value={selectNbrHeureSeance}
                    onChange={e => setSelectNbrHeureSeance(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>

            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Enseignant")}
                </Label>
                <Select
                  options={enseignant}
                  isSearchable={true}
                  value={selectEnseignant}
                  onChange={e => getType(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Type Matière</Label>
                <Select
                    options={type}
                    isSearchable={true}
                    value={selectType}
                    onChange={e => getMatiere(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Matiere</Label>
                <Select
                    options={matiere}
                    isSearchable={true}
                    value={selectMatiere}
                    onChange={e => getGroupe(e)}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Groupe</Label>
                <Select
                    options={groupe}
                    isSearchable={true}
                    value={selectGroupe}
                    onChange={e => getSalle(e)}
                />
              </div>
            </Col>
            <Col md="2">
              <div className="mb-3">
                <Label className="d-block mb-3">Choix des groupes :</Label>
                <div className="form-check form-check-inline">
                  <Input
                      type="radio"
                      id="customRadioInline1"
                      name="customRadioInline1"
                      className="form-check-input"
                      checked={tousEtudiant === 1}
                      disabled={true}
                      onChange={e => TousEtudiant(1)}
                  />
                  <Label
                      className="form-check-label"
                      htmlFor="customRadioInline1"
                  >
                    Groupe
                  </Label>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <Input
                      type="radio"
                      id="customRadioInline2"
                      name="customRadioInline1"
                      className="form-check-input"
                      checked={tousEtudiant === 2}
                      disabled={true}
                      onChange={e => filterEtudiant(2)}
                  />
                  <Label
                      className="form-check-label"
                      htmlFor="customRadioInline2"
                  >
                    Seule etudiant
                  </Label>
                </div>
              </div>
            </Col>


            <Col lg="2">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Etudiant</Label>
                <Select
                    options={etudiant}
                    isSearchable={true}
                    value={selectEtudiant}
                    onChange={e => setSelectEtudiant(e)}
                    isDisabled={selectDisabled}
                />
              </div>
            </Col>
            <Col lg="2">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Salle</Label>
                <Select
                    options={salle}
                    isSearchable={true}
                    value={selectSalle}
                    onChange={e => setSelectSalle(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>


          </Row>
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                {props.t("Annuler")}
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary "  disabled={!disbutReg} onClick={save}>
                {props.t("Confirmer")}
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(AddAvisTest))
AddAvisTest.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
