import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  Button,
  Label,
  Input,
  ModalHeader,
} from "reactstrap"
import { withRouter } from "react-router"
//i18n
import { withTranslation } from "react-i18next"
import Select from "react-select"
import { Link } from "react-router-dom"

const DataTableRattrapage = props => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [modalDelete, setModalDelete] = useState(false)
  const [idDelete, setIdDelete] = useState("")
  const [salle, setSalle] = useState([])
  const [selectSalle, setSelectSalle] = useState("")
  // Data Table
  const [orders, setOrders] = useState([])
  const [jourId, setJourId] = useState("")
  const [quartId, setQuartId] = useState("")
  const [semestre, setSemestre] = useState("")
  const [modalRefuse, setModalRefuse] = useState(false)
  const [cause, setCause] = useState("")
  const [idRefus, setIdRefus] = useState("")
  const [tousSalle, setTousSalle] = useState(2)
  var userAuthScolarite = JSON.parse(
      localStorage.getItem("userAuthScolarite")
  )
  var annee_id = userAuthScolarite.user.annee_id
  const [delet, setDelet] = useState(0);
  const [edit, setEdit] = useState(0);
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    alwaysShowAllBtns: true,
    sizePerPage: 40,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )
  const Columns = () => [
    {
      dataField: "date_creation",
      text: props.t("Crée le"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "enseignant",
      text: props.t("Enseignant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date",
      text: props.t("Date"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date_validation",
      text: props.t("Date Validation"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "quart",
      text: props.t("Quart"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "matiere",
      text: props.t("Matiere"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "type",
      text: props.t("Type"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "group",
      text: props.t("Groupe"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "etudiant",
      text: props.t("Etudiant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "salle",
      text: props.t("Salle"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Action",
      isDummyField: true,
      text: "Action",
      style: { textAlign: "center" },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
          edit === 1 ? (
              row.active == 0 ? (
                  <div style={{textAlign: "center"}}>
                    <div className="d-flex gap-3">
                      <Link to="#" className="text-success">
                        <i
                            className="mdi mdi-pencil"
                            id="edittooltip"
                            onClick={() => toggle(row)}
                        />
                      </Link>
                      <Link to="#" className="text-danger">
                        <i
                            className="fas fa-times"
                            id="deletetooltip"
                            onClick={() => toggleRefuse(row)}
                        />
                      </Link>
                    </div>
                  </div>
              ) : (
                  <i
                      style={{color: "#2ca67a", cursor: "pointer"}}
                      className="fas fa-check"
                  ></i>
              )
          ) : (
              <i
                  style={{color: "#a7a3a3", cursor: "pointer"}}
                  className="fas fa-check"
              ></i>
          )
    },
    {
      dataField: "Suppression",
      isDummyField: true,
      text: "Suppression",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          delet === 1 ? (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#ED6464", cursor: "pointer"}}
                    onClick={() => toggleDelete(row.id)}
                    className="fas fa-trash-alt"
                ></i>
              </div>
          ) : (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#a7a3a3", cursor: "pointer"}}
                    className="fas fa-trash-alt"
                ></i>
              </div>
          )
      ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Avis de rattrapage")
    );
    setEdit(typeArticleSubMenu.sous_menu[0].edit)
    setDelet(typeArticleSubMenu.sous_menu[0].delete)
    const res = await API.post("avis/rattrapage/list",{annee_id}).then(res => {
      setOrders(res.data.list)
      if (res.data.list.length != 0) {
        setJourId(res.data.list[0].jour_id)
        setQuartId(res.data.list[0].quart_id)
      }
    })
    setLoading(true)
  }, [])

  const toggle = async row => {
    setModal(true)
    setId(row.id)
    setJourId(row.jour_id)
    setQuartId(row.quart_id)
    setSemestre(row.semestre_id)
    const resS = await API.post("emploi/get_salle", {
      quart_id: row.quart_id,
      jour_id: row.jour_id,
      semestre_id: row.semestre_id,
      quan: 0,
    }).then(resS => {
      setSalle(resS.data.salles)
    })
  }

  const toggleRefuse = async row => {
    setModalRefuse(true)
    setIdRefus(row.id)
  }

  const toggleDelete = id => {
    setModalDelete(!modalDelete)
    setIdDelete(id)
  }

  const toggleDeleteRat = async () => {
    const resD = await API.post("avis/rattrapage/delete", {
      id: idDelete,
    }).then(resD => {
      setModalDelete(false)
      const res = API.post("avis/rattrapage/list",{annee_id}).then(res => {
        setOrders(res.data.list)
        if (res.data.list.length != 0) {
          setJourId(res.data.list[0].jour_id)
          setQuartId(res.data.list[0].quart_id)
        }
      })
      setLoading(true)
    })
  }

  const close = () => {
    setModal(false)
    setTousSalle(2)
  }

  const closeRefuse = () => {
    setModalRefuse(false)
  }

  const valide = async () => {
    setModal(false)
    setLoading(false)
    if (selectSalle != "") {
      const res = await API.post("avis/rattrapage/validate", {
        id: id,
        etat: 1,
        salle_id: selectSalle.value,
      }).then(res => {
        if(res.data.errNum == 403){

          toast.error("⛔ Groupe est dégà affectée !!", {
            containerId: "A",
          })
          setModal(false)
          const resD = API.post("avis/rattrapage/list",{annee_id}).then(resD => {
            setOrders(resD.data.list)
            setLoading(true)
          })
        }else{
          const resN = API.post("avis/rattrapage/notif_success", {
            id: id,
          }).then(resN => {
            setModal(false)
            const resD = API.post("avis/rattrapage/list",{annee_id}).then(resD => {
              setOrders(resD.data.list)
              setLoading(true)
            })
          })
        }

      })
    } else {
      toast.error("⛔ Salle obligatoire !", {
        containerId: "A",
      })
    }
  }

  const refuse = async () => {
    setModalRefuse(false)
    setLoading(false)
    const res = await API.post("avis/rattrapage/notif_decline", {
      id: idRefus,
      cause: cause,
    }).then(res => {
      const resN = API.post("avis/rattrapage/delete", {
        id: idRefus,
      }).then(resN => {
        setModal(false)
        const resD = API.post("avis/rattrapage/list",{annee_id}).then(resD => {
          setOrders(resD.data.list)
          setLoading(true)
        })
      })
    })
  }
  const TousSalle = async () => {
    setTousSalle(1)
    const res = await API.get("salle/select").then(res => {
      setSalle(res.data.Salle)
    })
  }
  const filterSalle = async () => {
    setTousSalle(2)
    const resS = await API.post("emploi/get_salle", {
      quart_id: quartId,
      jour_id: jourId,
      semestre_id: semestre,
      quan: 0,
    }).then(resS => {
      setSalle(resS.data.salles)
    })
  }

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      <Modal isOpen={modal} toggle={close} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
            fontWeight: "bold",
          }}
          toggle={close}
        >
          {props.t("Validation rattrapage ")}
        </div>
        <ModalBody>


          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label className="d-block mb-3">Choix des salles :</Label>
                <div className="form-check form-check-inline">
                  <Input
                      type="radio"
                      id="customRadioInline1"
                      name="customRadioInline1"
                      className="form-check-input"
                      checked={tousSalle === 1}
                      onChange={e => TousSalle(1)}
                  />
                  <Label
                      className="form-check-label"
                      htmlFor="customRadioInline1"
                  >
                    Tous Salles
                  </Label>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <Input
                      type="radio"
                      id="customRadioInline2"
                      name="customRadioInline1"
                      className="form-check-input"
                      checked={tousSalle === 2}
                      onChange={e => filterSalle(2)}
                  />
                  <Label
                      className="form-check-label"
                      htmlFor="customRadioInline2"
                  >
                    Filtrer Salles
                  </Label>
                </div>
              </div>
            </Col>

          </Row>




          <Row>

            <Col lg="12">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Salle</Label>
                <Select
                  options={salle}
                  isSearchable={true}
                  onChange={e => setSelectSalle(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <div
              className="mt-3"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button className="btn btn-success" onClick={valide}>
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-check-circle"
                  ></i>
                  {props.t("Valider")}
                </Button>
              </div>
              <div>
                <Button onClick={close} className="btn btn-warning">
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-times-circle"
                  ></i>
                  {props.t("Annuler")}
                </Button>
              </div>
            </div>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalRefuse} toggle={closeRefuse} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
            fontWeight: "bold",
          }}
          toggle={closeRefuse}
        >
          {props.t("Refus rattrapage ")}
        </div>
        <ModalBody>
          <Row>
            <Col lg="12">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Cause : </Label>
                <Input
                  lg="3"
                  className="form-control"
                  placeholder="Cause ..."
                  type="textarea"
                  onChange={e => setCause(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <div
              className="mt-3"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button className="btn btn-danger" onClick={refuse}>
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-check-circle"
                  ></i>
                  {props.t("Refuser")}
                </Button>
              </div>
              <div>
                <Button onClick={closeRefuse} className="btn btn-warning">
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-times-circle"
                  ></i>
                  {props.t("Annuler")}
                </Button>
              </div>
            </div>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalDelete} toggle={toggleDelete} centered={true}>
        <ModalHeader
          style={{ width: "100% !important", textAlign: "center !important" }}
          toggle={toggleDelete}
          tag="h4"
        >
          {"Suppression rattrapage"}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Voulez-vous supprimer cette rattrapage ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleDeleteRat}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleDelete}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableRattrapage))
DataTableRattrapage.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
