import React, {Fragment, useEffect, useState} from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import AddSurveillance from "./AddSurveillance"
import ImportationSurveillance from "./ImportationSurveillance"
import DetailSurSem from "./DetailSurSem"

const Surveillance = props => {
  const [section, setSection] = useState(1)
  const [add, setAdd] = useState(0);
  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Surveillance")
    );
    setAdd(typeArticleSubMenu.sous_menu[0].add)
  }, [])
  const back = () => {
    setSection(1)
  }

  const renderBlock = section => {
    if (section === 1) {
      return <DetailSurSem />
    } else if (section === 2) {
      return <AddSurveillance setSection={setSection} back={back} />
    } else if (section === 3) {
      return <ImportationSurveillance setSection={setSection} back={back} />
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Surveillance | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
                >
                  Emploi surveillance par Enseignant
                </NavLink>
              </NavItem>
              {add === 1 ?(
                  <Fragment>
                    <NavItem>
                      <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: section === 2,
                          })}
                          onClick={() => {
                            setSection(2)
                          }}
                      >
                        Ajouter surveillance
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: section === 3,
                          })}
                          onClick={() => {
                            setSection(3)
                          }}
                      >
                        Importation surveillance
                      </NavLink>
                    </NavItem>
                  </Fragment>
              ):(null)}
            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Surveillance)
