import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import DataTableValidationSemestre1 from "./DataTableValidationSemestre1"
import APIS from "../../api"
import DataTableValidationSemestre2 from "./DataTableValidationSemestre2"

const FicheVoeuxEns = props => {
  const [section, setSection] = useState(2)
  const [chefDepartement, setChefDepartement] = useState("")
  var url = window.location.href
  var array = url.split("=")
  var enseignant_id = array[1]

  useEffect(async () => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const res = await APIS.post("enseignant/get_chef", {
      enseignant_id,annee_id
    }).then(res => {
      setChefDepartement(res.data.chef.etat)
    })
  }, [])

  const renderBlock = section => {
    if (section === 2) {
      if (chefDepartement == 1) {
        return <DataTableValidationSemestre1 id={enseignant_id} />
      }
    } else if (section === 3) {
      if (chefDepartement == 1) {
        return <DataTableValidationSemestre2 id={enseignant_id} />
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{props.t("Fiche de voeux")} | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              {chefDepartement === 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: section === 2,
                    })}
                    onClick={() => {
                      setSection(2)
                    }}
                  >
                    {props.t("Validation fiche de voeux semestre 1")}
                  </NavLink>
                </NavItem>
              ) : null}
              {chefDepartement === 1 ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: section === 3,
                    })}
                    onClick={() => {
                      setSection(3)
                    }}
                  >
                    {props.t("Validation fiche de voeux semestre 2")}
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(FicheVoeuxEns))
FicheVoeuxEns.propTypes = {
  t: PropTypes.any,
}
