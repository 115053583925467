import React, {useEffect, useState} from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import AddSalle from "./AddSalle"
import DataTableSalle from "./DataTableSalle"

const Salle = props => {
  const [section, setSection] = useState(1)
  const [add, setAdd] = useState(0);
  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Salle")
    );
    setAdd(typeArticleSubMenu.sous_menu[4].add)
  }, [])
  const back = () => {
    setSection(1)
  }

  const renderBlock = section => {
    if (section === 1) {
      return <DataTableSalle/>
    } else if (section === 2) {
      return <AddSalle setSection={setSection} back={back} />
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Salle | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
                >
                  Salle
                </NavLink>
              </NavItem>
              {add === 1 ?  (
                  <NavItem>
                    <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: section === 2,
                        })}
                        onClick={() => {
                          setSection(2)
                        }}
                    >
                      Ajouter salle
                    </NavLink>
                  </NavItem>
              ):(null)}

            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Salle)
