import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import APIS from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button, Label,
} from "reactstrap"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next";

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import getDay from "date-fns/getDay"
import API from "../../api";
import Select from "react-select";
const DataTableDemandeStageMastereModif = props => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [idStage, setIdStage] = useState("")
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    alwaysShowAllBtns: true,
    sizePerPage: 20,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const download = (row) => {
    APIS.post(
        "etudiant/download_file",
        {
          id: row.id,
        },
        { responseType: "blob" }
    ).then(res => {
      const contentDisposition = res.headers["content-disposition"];
      const match = /filename="(.+)"/.exec(contentDisposition);
      const fileName = match ? match[1] : `${row.fichier_stage}`;
      const blob = new Blob([res.data], { type: res.data.type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }

  const Columns = () => [
    {
      dataField: "etudiant.etudiant_name",
      text: props.t("Etudiant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "enseignant.enseignant_name",
      text: props.t("Enseignant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "titre",
      text: props.t("Titre"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "durée_du_stage",
      text: props.t("Durée du stage"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "fichier_stage",
      text: props.t("Fichier de Stage"),
      sort: true,
      style: {textAlign: "center"},
      headerStyle: (colum, colIndex) => {
        return {textAlign: "center"}
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          imp === 1 ? (
              <i style={{color: "#955cd5", cursor: "pointer"}}
                 className="fas fa-file-download"
                 onClick={() => download(row)}
              ></i>
          ) : (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#a7a3a3", cursor: "pointer"}}
                    className="fas fa-file-download"
                ></i>
              </div>
          )
      ),
    },
    {
      dataField: "stage_status_id",
      isDummyField: true,
      text: props.t("Autorisation de dépôt"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          row.status_depo_stage == 0 ? (
              <div style={{display: "flex", justifyContent: "center"}}><i style={{color: "#FF0000", cursor: "pointer"}}
                                                                          className="fas fa-spinner fa-pulse"></i></div>
          ) :  row.status_depo_stage == 1 ? (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "green", cursor: "pointer"}}
                    className="fas fa-check"

                ></i>
              </div>

          ):(
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "red", cursor: "pointer"}}
                    className="fas fa-times"

                ></i>
              </div>
          )
      )
    },
    {
      dataField: "Modifier Sujet",
      isDummyField: true,
      text: props.t("Modifier Sujet"),
      style: {textAlign: "center"},
      headerStyle: (colum, colIndex) => {
        return {textAlign: "center"}
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
          add === 1 ? (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#dbc399", cursor: "pointer"}}
                    onClick={() => toggle(row.id)}
                    className="fas fa-edit"
                ></i>
              </div>

          ) : (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#a7a3a3", cursor: "pointer"}}
                    className="fas fa-times"
                ></i>
              </div>
          )
    },
  ]

  const { SearchBar } = Search
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  const [add, setAdd] = useState(0);
  const [imp, setImp] = useState(0);
  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Gestion Mastère")
    );
    setAdd(typeArticleSubMenu.sous_menu[0].add)
    setImp(typeArticleSubMenu.sous_menu[0].imp)
    const res = await APIS.post("etudiant/get_all_stage_non_valider",{
      annee_id:annee_id,
      type_stage:2
    }).then(res => {
      setOrders(res.data.stages)
    })
    setLoading(true)
  }, [])

  const toggle = id => {
    setModal(!modal)
    setIdStage(id)
  }
  const toggleDelete = async () => {
    const res = await APIS.post("etudiant/Softdelete_stage", {
      id: idStage,
    }).then(res => {
      APIS.post("etudiant/get_all_stage_non_valider",{
        annee_id:annee_id,
        type_stage:2
      }).then(res => {
        setOrders(res.data.stages)
      })
      setLoading(true)
    })
  }


  useEffect(() => {
    setModal(false)
  }, [orders])

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement")} ...{" "}
            </h4>
          </div>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader
            style={{ width: "100% !important", textAlign: "center !important" }}
            toggle={toggle}
            tag="h4"
        >
          {props.t("Modifier Stage")}
        </ModalHeader>
        <ModalBody>
          <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {props.t("Êtes-Vous sûr de vouloir modifier ce stage ?")}
              </p>
            </div>
            <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
            >
              <div>
                <Button
                    color="info"
                    className="btn-rounded "
                    onClick={toggleDelete}
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                  ></i>
                  {props.t("Oui")}
                </Button>
              </div>
              <div>
                <Button
                    onClick={toggle}
                    color="danger"
                    className="btn-rounded "
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                  ></i>
                  {props.t("Non")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableDemandeStageMastereModif))
DataTableDemandeStageMastereModif.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
