import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button, Label, Input,
} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import {toast} from "react-toastify";

const DataTableAgentDemandeEnseignant = props => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  // Data Table
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  // var user_id = userAuthScolarite.user.id
  var annee_id = userAuthScolarite.user.annee_id
  const [add, setAdd] = useState(0);
  const [imp, setImp] = useState(0);
  const NoDataIndication = () => (
      <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "enseignant",
      text: props.t("Enseignant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "type",
      text: props.t("Type de demande"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "langue",
      text: props.t("Langue de demande"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "numero_ordre",
      text: props.t("Numéro d'order"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "file",
      text: props.t("Fichier"),
      sort: true,
      style: {textAlign: "center"},
      headerStyle: (colum, colIndex) => {
        return {textAlign: "center"}
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          imp === 1 ?(
                  <i style={{color: "#955cd5", cursor: "pointer"}}
                     className="fas fa-file-download"
                     onClick={() => download(row)}
                  ></i>
              ):(
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#a7a3a3", cursor: "pointer"}}
                    className="fas fa-file-download"
                ></i>
              </div>
          )

      ),
    },
    {
      dataField: "valider_bureaux_ordre",
      isDummyField: true,
      text: props.t("Etat"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          row.valider_bureaux_ordre == 0 ? (
              <div style={{display: "flex", justifyContent: "center"}}><i style={{color: "#FF0000", cursor: "pointer"}}
                                                                          className="fas fa-spinner fa-pulse"></i></div>
          ) :  row.valider_bureaux_ordre == 1 && row.valider_agent == 1 && row.valider ==1 ? (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "green", cursor: "pointer"}}
                    className="fas fa-check-double"

                ></i>
              </div>
          ):row.valider_bureaux_ordre == 2 || row.valider_agent == 2 ?(
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "red", cursor: "pointer"}}
                    className="fas fa-times"

                ></i>
              </div>
          ) : (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "red", cursor: "pointer"}}
                    className="fas fa-check"

                ></i>
              </div>
          )
      )
    },
    {
      dataField: "Validation",
      isDummyField: true,
      text: props.t("Validation"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
          add === 1 ? (
                  row.valider_bureaux_ordre ==1 ? (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <i
                            style={{ color: "#ED6464", cursor: "pointer" }}
                            onClick={() => toggle(row.id)}
                            className="fas fa-check"
                        ></i>
                      </div>
                  ) :  row.valider_bureaux_ordre == 1 && row.valider_agent == 1 && row.valider ==1 ? (
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#80aed6", cursor: "not-allowed"}}
                            className="fas fa-check-double"

                        ></i>
                      </div>
                  ):(
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <i
                            style={{ color: "#ED6464", cursor: "not-allowed" }}
                            className="fas fa-times"
                            title="Cete demande déja utilisé"
                        ></i>
                      </div>
                  )
              ):(
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#a7a3a3", cursor: "pointer"}}
                    className="fas fa-times"
                ></i>
              </div>
          )

    },

  ]

  const { SearchBar } = Search
  const download = (row) => {
    API.post(
        "demande/enseignant/download",
        {
          id: row.id
        },
        { responseType: "blob" }
    ).then(res => {
      const contentDisposition = res.headers["content-disposition"];
      const match = /filename="(.+)"/.exec(contentDisposition);
      const fileName = match ? match[1] : `${row.file}`;
      const blob = new Blob([res.data], { type: res.data.type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }
  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth[17].sous_menu[1]
    setAdd( userAuth[17].sous_menu[1].add)
    setImp( userAuth[17].sous_menu[1].imp)
    // console.log(typeArticleSubMenu)
    const res = await API.post("demande/enseignant/list/scolarite",{annee_id}).then(res => {
      setOrders(res.data.DemandeEnseignant)
    })
    setLoading(true)
  }, [])
  const toggle = id => {
    setModal(!modal)
    setId(id)
  }
  const toggleDelete = async () => {
    const res = await API.post("demande/enseignant/update/agent/demande", {
      id: id,
      valider_agent:1
    }).then(res => {
      API.post("demande/enseignant/list/scolarite",{annee_id}).then(res => {
        setOrders(res.data.DemandeEnseignant)
      })
      setLoading(true)
    })
  }
  const toggleRefus = async () => {
    const res = await API.post("demande/enseignant/update/agent/demande", {
      id: id,
      valider_agent:2
    }).then(res => {
      API.post("demande/enseignant/list/scolarite",{annee_id}).then(res => {
        setOrders(res.data.DemandeEnseignant)
      })
      setLoading(true)
    })
  }
  useEffect(() => {
    setModal(false)
  }, [orders])
  return (
      <React.Fragment>
        <div>
          {loading ? (
              <Row>
                <Col xs="12">
                  <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={Columns()}
                      search
                      bootstrap4
                  >
                    {toolkitProps => (
                        <div>
                          <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <BootstrapTable
                              wrapperClasses="table-responsive"
                              noDataIndication={() => <NoDataIndication />}
                              striped={false}
                              bordered={false}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"table-light"}
                              hover
                              pagination={paginationFactory(pageOptions)}
                              {...toolkitProps.baseProps}
                          />
                        </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
          ) : (
              <div>
                <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                >
                  {" "}
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                >
                  {props.t("Chargement")}
                </h4>
              </div>
          )}
        </div>
        <Modal isOpen={modal} toggle={toggle} centered={true}>
          <ModalHeader
              style={{ width: "100% !important", textAlign: "center !important" }}
              toggle={toggle}
              tag="h4"
          >
            {props.t("Validation de Demande enseignant")}
          </ModalHeader>
          <ModalBody>
            <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>
                  {props.t("Êtes-Vous sûr de vouloir valider cette")}{" "}
                  {props.t("demande enseignant")} ?
                </p>
              </div>
              <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
              >
                <div>
                  <Button
                      color="info"
                      className="btn-rounded "
                      onClick={toggleDelete}
                  >
                    <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-up"
                    ></i>
                    {props.t("Oui")}
                  </Button>
                </div>
                <div>
                  <Button
                      onClick={toggleRefus}
                      color="danger"
                      className="btn-rounded "
                  >
                    <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-down"
                    ></i>
                    {props.t("Non")}
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableAgentDemandeEnseignant))
DataTableAgentDemandeEnseignant.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
