import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Row, Col, Spinner } from "reactstrap"
import { withRouter } from "react-router"
import { ToastContainer, toast, Slide } from "react-toastify"

const DataTableScheduleSalle = props => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    sizePerPage: 50,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }
  var userAuthScolarite = JSON.parse(
      localStorage.getItem("userAuthScolarite")
  )
  var annee_id = userAuthScolarite.user.annee_id
  const [view, setView] = useState(0);
  const [imp, setImp] = useState(0);
  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "name",
      text: "Salle",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "emploi",
      isDummyField: true,
      text: "Emploi Semestre "+props.semestre,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          view === 1 ?(
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <i
                        style={{ color: "cornflowerblue", cursor: "pointer" }}
                        className="fas fa-eye"
                        onClick={() =>
                            props.history.push(
                                "/EmploiSalle?id=" + row.id + "/" + row.semestre
                            )
                        }
                    ></i>
                  </div>
              ):(
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#a7a3a3", cursor: "pointer"}}
                    className="fas fa-eye"
                ></i>
              </div>
          )

      ),
    },
    {
      dataField: "emplois",
      isDummyField: true,
      text: "Téléchargement",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          imp === 1 ? (
              <a
                  href={
                      "https://ismsf.scolarite.backcresus-institut.ovh/api/impression_salle_shedules/" +
                      row.id +
                      "/" +
                      row.semestre + "/" + annee_id
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{display: "flex", justifyContent: "center"}}
              >
                <i
                    style={{color: "#955cd5", cursor: "pointer"}}
                    className="fas fa-file-download"
                ></i>
              </a>
          ) : (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#a7a3a3", cursor: "pointer"}}
                    className="fas fa-file-download"
                ></i>
              </div>
          )
      ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Emploi salle")
    );
    setView(typeArticleSubMenu.sous_menu[0].view)
    setImp(typeArticleSubMenu.sous_menu[0].imp)
    const res = await API.post("salle/list_emploi", {
      semestre: props.semestre,
    }).then(res => {
      setOrders(res.data.Salle)
      setLoading(true)
    })
  }, [props.semestre])

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row className="mt-4">
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <div>
                      <div className="mb-3 row" style={{ display: "flex" }}>
                        <div className="col-md-6 mb-3 row">
                          <div className="col-md-8 search-box-etd">
                            <div
                              className="search-box me-2 mb-2 d-inline-block"
                              style={{ width: "100%" }}
                            >
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div>
                        </div>
                        {imp ===  1 ? (
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                className="col-md-6 mb-3 row"
                            >
                              <div
                                  style={{ textAlign: "right" }}
                                  className="col-md-8 select-action"
                              >
                                <a
                                    href={
                                        "https://ismsf.scolarite.backcresus-institut.ovh/api/impression_salles_shedules" +
                                        "/" +
                                        props.semestre +"/"+annee_id
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                  <button className="btn btn-primary btn btn-primary">
                                    <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                    Emplois salles
                                  </button>
                                </a>
                              </div>
                            </div>
                        ):(null)}

                      </div>
                    </div>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              En cours ...{" "}
            </h4>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(DataTableScheduleSalle)
DataTableScheduleSalle.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
  semestre: PropTypes.any,
}
