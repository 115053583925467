import React, { useState, useEffect, useMemo } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Button,
  Col,
  Form,
  Label,
  Row,
  Card,
  CardBody,
  Container,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withTranslation } from "react-i18next"
import Select from "react-select"
import { Spinner } from "reactstrap/lib"

const EditAffectation = props => {
  const [departement, setDepartement] = useState([])
  const [selectDepartement, setSelectDepartement] = useState("")
  const [enseignant, setEnseignant] = useState([])
  const [selectEnseignant, setSelectEnseignant] = useState("")
  const [loading, setLoading] = useState(false)
  const [annee, setAnnee] = useState("");
  const [specialite, setSpecialite] = useState([]);
  const [selectSpecialite, setSelectSpecialite] = useState("");
  useEffect(async () => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    setAnnee(annee_id)
    //   Département
    const resD = await API.get("departement/select").then(resD => {
      setDepartement(resD.data.Departement)
    })
    //    Enseignant
    const resE = await API.get("enseignant/select").then(resE => {
      setEnseignant(resE.data.select_enseignant)
    })
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("departement/ensignant/get_by_id", { id }).then(
        res => {
          setSelectDepartement(res.data.Departement.departement)
          setSelectEnseignant(res.data.Departement.ensignant)
          setSelectSpecialite(res.data.Departement.departement)
          setLoading(true)
        }
    )
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (selectDepartement == "") {
      toast.error("⛔ Département obligatoire", {
        containerId: "A",
      })
    } else if (selectSpecialite == "") {
      toast.error("⛔ Spécialite obligatoire", {
        containerId: "A",
      })
    } else  if (selectEnseignant != "") {
      var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
      var annee_id = userAuthScolarite.user.annee_id
      const res = await API.post("departement/ensignant/edit", {
        id: id,
        departement_id: selectDepartement.value,
        enseignant_id: selectEnseignant.value,
        specialite_id:selectSpecialite.value,
        annee_id:annee_id
      })
          .then(res => {
            if(res.data.errNum == 422){
              toast.error("⛔  Chef département est déjà existe !", {
                containerId: "A",
              })
            }else{
              props.history.push("/Departement")
            }

          })
          .catch(() => {
            toast.error("⛔ Problème lors de l'insertion !", {
              containerId: "A",
            })
          })
    } else {
      toast.error("⛔ chef département obligatoire", {
        containerId: "A",
      })
    }
  }
  const getSpecialite = async event => {
    setSelectDepartement(event)
    setSelectSpecialite("")
    setSelectEnseignant("")
    const res = await API.post("specialite/select_by_departement_id", {
      departement_id: event.value,
    }).then(res => {
      setSpecialite(res.data.Specialite)
    })
  }
  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Card>
                <CardBody>
                  {loading ? (
                      <div>
                        <Form>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  {props.t("Département")} :
                                </Label>
                                <Select
                                    options={departement}
                                    isSearchable={true}
                                    value={selectDepartement}
                                    onChange={e => getSpecialite(e)}
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  {props.t("Spécialite")} :
                                </Label>
                                <Select
                                    value={selectSpecialite}
                                    options={specialite}
                                    isSearchable={true}
                                    onChange={e => setSelectSpecialite(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>

                            <Col md={6}>
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  {props.t("Chef département")} :
                                </Label>
                                <Select
                                    value={selectEnseignant}
                                    options={enseignant}
                                    isSearchable={true}
                                    onChange={e => setSelectEnseignant(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          {" "}
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                className="text-center mt-4"
                            >
                              <Button
                                  type="button"
                                  color="warning"
                                  className="btn btn-warning  mb-2 me-2"
                                  onClick={() => props.history.push("/Departement")}
                              >
                                {props.t("Annuler")}
                              </Button>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                                className="text-center mt-4"
                            >
                              <button
                                  type="button"
                                  className="btn btn-primary "
                                  onClick={edit}
                              >
                                {props.t("Confirmer")}
                              </button>
                            </div>
                          </Col>
                        </div>
                      </div>
                  ) : (
                      <div>
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                        >
                          {" "}
                          <Spinner type="grow" className="ms-6" color="primary" />
                        </div>
                        <h4
                            style={{ textAlign: "center", marginTop: "2%" }}
                            className="ms-6"
                        >
                          {" "}
                          {props.t("Chargement")} ...{" "}
                        </h4>
                      </div>
                  )}
                </CardBody>
              </Card>
            </Row>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
          </Container>
        </div>
      </React.Fragment>
  )
}
export default withRouter(withTranslation()(EditAffectation))
EditAffectation.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
