import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import APIS from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button, Label,
} from "reactstrap"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next";

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import getDay from "date-fns/getDay"
import API from "../../api";
import Select from "react-select";
const DataTableDemandeStageMastere = props => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [idStage, setIdStage] = useState("")
  const [date, setDate] = useState(null)
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    alwaysShowAllBtns: true,
    sizePerPage: 20,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const download = (row) => {
    APIS.post(
        "etudiant/download_file",
        {
          id: row.id,
        },
        { responseType: "blob" }
    ).then(res => {
      const contentDisposition = res.headers["content-disposition"];
      const match = /filename="(.+)"/.exec(contentDisposition);
      const fileName = match ? match[1] : `${row.fichier_stage}`;
      const blob = new Blob([res.data], { type: res.data.type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }

  const Columns = () => [
    {
      dataField: "etudiant_id",
      text: props.t("Etudiant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "enseignant_id",
      text: props.t("Enseignant"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "titre",
      text: props.t("Titre"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "durée_du_stage",
      text: props.t("Durée du stage"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "soutenance_date",
      text: props.t("Date soutenance"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "salle",
      text: props.t("Salle"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "quart",
      text: props.t("Quart"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },

    {
      dataField: "fichier_stage",
      text: props.t("Fichier de Stage"),
      sort: true,
      style: {textAlign: "center"},
      headerStyle: (colum, colIndex) => {
        return {textAlign: "center"}
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          imp === 1 ?(
                  <i style={{color: "#955cd5", cursor: "pointer"}}
                     className="fas fa-file-download"
                     onClick={() => download(row)}
                  ></i>
              ):(
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#a7a3a3", cursor: "pointer"}}
                    className="fas fa-file-download"
                ></i>
              </div>
          )
      ),
    },
    {
      dataField: "stage_status_id",
      isDummyField: true,
      text: props.t("Autorisation de dépôt"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          row.status_depo_stage == 0 ? (
              <div style={{display: "flex", justifyContent: "center"}}><i style={{color: "#FF0000", cursor: "pointer"}}
                                                                          className="fas fa-spinner fa-pulse"></i></div>
          ) :  row.status_depo_stage == 1 ? (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "green", cursor: "pointer"}}
                    className="fas fa-check"

                ></i>
              </div>

          ):(
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "red", cursor: "pointer"}}
                    className="fas fa-times"

                ></i>
              </div>
          )
      )
    },
    {
      dataField: "validation date soutenance",
      isDummyField: true,
      text: props.t("Validation date soutenance"),
      style: {textAlign: "center"},
      headerStyle: (colum, colIndex) => {
        return {textAlign: "center"}
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
          add === 1 ?(
                  row.soutenance_date == null ? (
                      <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#dbc399", cursor: "pointer"}}
                            onClick={() => toggle(row.id)}
                            className="fas fa-calendar-check"
                        ></i>
                      </div>
                  ) : (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <i
                            style={{ color: "#ff8f00"}}
                            className="fas fa-check-double"
                        ></i>
                      </div>
                  )
              ):(
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#a7a3a3", cursor: "pointer"}}
                    className="fas fa-times"
                ></i>
              </div>
          )

    },
  ]

  const { SearchBar } = Search
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  const [quart, setQuart] = useState([])
  const [salle, setSalle] = useState([])
  const [selectSalle, setSelectSalle] = useState("")
  const [selectQuart, setSelectQuart] = useState("")
  const [add, setAdd] = useState(0);
  const [imp, setImp] = useState(0);
  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Gestion Mastère")
    );
    setAdd(typeArticleSubMenu.sous_menu[0].add)
    setImp(typeArticleSubMenu.sous_menu[0].imp)
    const res = await APIS.post("etudiant/get_all_stage_valider",{
      id:annee_id,
      type_stage:2
    }).then(res => {
      setOrders(res.data.stage_valider)
    })
    setLoading(true)
  }, [])

  const toggle = id => {
    setModal(!modal)
    setIdStage(id)
    setDate(null)
    setSelectQuart("")
    setSelectSalle("")
    API.get("quart/select_debut").then(resQ => {
      setQuart(resQ.data.Quart)
    })
  }
  const getSalle = async e => {
    let dateExamen = date
    let month = "" + (dateExamen.getMonth() + 1)
    let day = "" + dateExamen.getDate()
    let year = dateExamen.getFullYear()
    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day
    let convertDate = [year, month, day].join("-")
    setSelectQuart(e)
    API.post("etudiant/get_salle_dispo", {
      date: convertDate,
      annee_id,
      quart:e.value
    }).then(res => {
      setSalle(res.data.salle_ids)
    })
  }
  const toggleDelete = async () => {
    let dateExamen = date
    let month = "" + (dateExamen.getMonth() + 1)
    let day = "" + dateExamen.getDate()
    let year = dateExamen.getFullYear()
    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day
    let convertDate = [year, month, day].join("-")
    const res = await APIS.post("etudiant/date_sout_stage", {
      id: idStage,
      soutenance_date:convertDate,
      salle_id:selectSalle.value,
      quart_id:selectQuart.value
    }).then(res => {
      setDate(null)
      setSelectSalle("")
      setSelectQuart("")
     APIS.post("etudiant/get_all_stage_valider",{
        id:annee_id,
        type_stage:2
      }).then(resList => {
        setOrders(resList.data.stage_valider)
      })
      setLoading(true)
    })
  }


  useEffect(() => {
    setModal(false)
  }, [orders])

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement")} ...{" "}
            </h4>
          </div>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader
          style={{ width: "100% !important", textAlign: "center !important" }}
          toggle={toggle}
          tag="h4"

        >
          {props.t("Validation date de soutenance")}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "900",
              LineHeight: "18.375px",
            }}
          >
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Date de soutenance :
                  </Label>
                  <Col md={12} className="pr-0">
                    <DatePicker
                        className="form-control ddate"
                        selected={date}
                        onChange={e => setDate(e)}
                        dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    À partir de :
                  </Label>
                  <Col md={12} className="pr-0">
                    <Select
                        options={quart}
                        isSearchable={true}
                        value={selectQuart}
                        onChange={e => getSalle(e)}
                    />
                  </Col>
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                   Salle :
                  </Label>
                  <Col md={12} className="pr-0">
                    <Select
                        options={salle}
                        isSearchable={true}
                        value={selectSalle}
                        onChange={e => setSelectSalle(e)}
                    />
                  </Col>
                </div>
              </Col>
            </Row>

            <Row>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {" "}
                <Col lg="6">
                  <div className="text-center mt-4">
                    <button
                        type="button"
                        className="btn btn-primary "
                        onClick={toggleDelete}
                    >
                      CONFIRMER
                    </button>
                  </div>
                </Col>

              </div>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableDemandeStageMastere))
DataTableDemandeStageMastere.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
