import React, { useState, useEffect, useMemo } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import countryList from "react-select-country-list"
import API from "../../api"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

const AddEnseignant = props => {
  const [disbutReg, setDisbutReg] = useState(true)
  const [nom, setNom] = useState("")
  const [prenom, setPrenom] = useState("")
  const [numero, setNumero] = useState("")
  const [selectNationnalite, setSelectNationnalite] = useState("")
  const [lieuNaissance, setLieuNaissance] = useState("")
  const [email, setEmail] = useState("")
  const [tel, setTel] = useState("")
  const [rue, setRue] = useState("")
  const [codePostal, setCodePostal] = useState("")
  const [pays, setPays] = useState("")
  const [gouvernorat, setGouvernorat] = useState("")
  const [date, setDate] = useState("")
  const [grade, setGrade] = useState("")
  const [selectGrade, setSelectGrade] = useState("")
  const [gender, setGender] = useState(1)
  const [type, setType] = useState(1)
  const [etatCivil, setEtatCivil] = useState(1)
  const [file, setFile] = useState(null)
  const [departement, setDepartement] = useState([])
  const [selectDepartement, setSelectDepartement] = useState("")
  const [structure, setStructure] = useState("")
  const [statut, setStatut] = useState([])
  const [selectStatut, setSelectStatut] = useState("")
  const [typeInstrument, setTypeInstrument] = useState([]);
  const [selectTypeInstrument, setSelectTypeInstrument] = useState("");
  const [typeInstrument1, setTypeInstrument1] = useState([]);
  const [selectTypeInstrument1, setSelectTypeInstrument1] = useState("");
  const options = useMemo(() => countryList().getData(), [])

  useEffect(async () => {
    //   Département
    const resD = await API.get("departement/select").then(resD => {
      setDepartement(resD.data.Departement)
    })
    const resG = await API.get("grade/select").then(resG => {
      setGrade(resG.data.Grade)
    })
    // Statut
    const resS = await API.get("statut/select").then(resS => {
      setStatut(resS.data.Statut)
    })
    const resType = await API.get("type/instrument/select").then(resType => {
      setTypeInstrument(resType.data.TypeInstrument)
    })
    const resType1 = await API.get("type/instrument/select").then(resType1 => {
      setTypeInstrument1(resType1.data.TypeInstrument)
    })
  }, [])

  const editDate = date => {
    setDate(date)
  }

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const save = async () => {
    setDisbutReg(false)
    if (date != "") {
      let dateNaiss = date
      let month = "" + (dateNaiss.getMonth() + 1)
      let day = "" + dateNaiss.getDate()
      let year = dateNaiss.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }
    //Test si file vide
    var pathFile = ""
    if (file != null) {
      pathFile = file.name
    }
    var grade_id = selectGrade.value
    var nationalite_id = selectNationnalite.label
    if( selectGrade ==""){
      setDisbutReg(true)
      toast.error("⛔ Grade obligatoires", {
        containerId: "A",
      })
    }else if( selectDepartement =="") {
      setDisbutReg(true)
      toast.error("⛔ Departement obligatoires", {
        containerId: "A",
      })
    }else if(selectStatut ==""){
      setDisbutReg(true)
      toast.error("⛔ Status obligatoires", {
        containerId: "A",
      })
    }else if (nom == "" || prenom == "" || numero == "" ) {
      setDisbutReg(true)
      toast.error("⛔ Nom , Prenom , CIN obligatoires", {
        containerId: "A",
      })
    } else {
      const res = await API.post("enseignant/add", {
        firstName: nom,
        lastName: prenom,
        gender: gender,
        type: type,
        code: numero,
        nationality: nationalite_id,
        birthplace: lieuNaissance,
        email: email,
        telephone: tel,
        birthdate: convertDate,
        addressStreet: rue,
        addressZipCode: codePostal,
        addressCountry: pays,
        addressState: gouvernorat,
        civilState: etatCivil,
        grade_id: grade_id,
        departement_id: selectDepartement.value,
        statut_id: selectStatut.value,
        fichier: pathFile,
        structure: structure,
        active: 0,
        type_instrument_id:selectTypeInstrument.value,
        type_instrument2_id:selectTypeInstrument1.value
      }).then(res => {
        if(res.data.errNum == 500){
          setDisbutReg(true)
          toast.error("⛔ Ce cin exist déjà pour un autre utilisateur", {
            containerId: "A",
          })
        }else if(res.data.errNum ==501){
          setDisbutReg(true)
          toast.error("⛔ Ce mail exist déjà pour un autre utilisateur", {
            containerId: "A",
          })
        }else  if (res.data.status === 200) {
          if (pathFile != "") {
            const formData = new FormData()
            formData.append("document", file)
            formData.append("id", res.data.Enseignant.id)
            const resA = API.post("enseignant/import_photo", formData)
          }
          props.setSection(1)
        }
      })
    }
  }

  return (
      <React.Fragment>
        <Row>
          <AvForm>
            <Form>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Nom :</Label>
                    <AvField
                        name="nom"
                        placeholder="Entrer nom"
                        type="text"
                        errorMessage="* nom obligatoire"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        onChange={e => setNom(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Prénom :</Label>
                    <AvField
                        name="prenom"
                        placeholder="Entrer prénom"
                        type="text"
                        errorMessage="* prenom obligatoire"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        onChange={e => setPrenom(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="d-block mb-3">Sexe :</Label>
                    <div className="form-check form-check-inline">
                      <Input
                          type="radio"
                          id="customRadioInline1"
                          name="customRadioInline1"
                          className="form-check-input"
                          defaultChecked
                          onClick={() => setGender(1)}
                      />
                      <Label
                          className="form-check-label"
                          htmlFor="customRadioInline1"
                      >
                        Masculin
                      </Label>
                    </div>
                    &nbsp;
                    <div className="form-check form-check-inline">
                      <Input
                          type="radio"
                          id="customRadioInline2"
                          name="customRadioInline1"
                          className="form-check-input"
                          onClick={() => setGender(2)}
                      />
                      <Label
                          className="form-check-label"
                          htmlFor="customRadioInline2"
                      >
                        Feminin
                      </Label>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Nationalité :</Label>
                    <Select
                        options={options}
                        isSearchable={true}
                        onChange={e => setSelectNationnalite(e)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="d-block mb-3">Type :</Label>
                    <div className="form-check form-check-inline">
                      <Input
                          type="radio"
                          id="customRadioInline3"
                          name="customRadioInline3"
                          className="form-check-input"
                          defaultChecked
                          onClick={() => setType(1)}
                      />
                      <Label
                          className="form-check-label"
                          htmlFor="customRadioInline3"
                      >
                        CIN
                      </Label>
                    </div>
                    &nbsp;
                    <div className="form-check form-check-inline">
                      <Input
                          type="radio"
                          id="customRadioInline4"
                          name="customRadioInline3"
                          className="form-check-input"
                          onClick={() => setType(2)}
                      />
                      <Label
                          className="form-check-label"
                          htmlFor="customRadioInline4"
                      >
                        Passeport
                      </Label>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Identifiant</Label>
                    <AvField
                        name="numero"
                        placeholder="Entrer identifiant"
                        type="number"
                        errorMessage="Min 8 caractère"
                        className="form-control"
                        validate={{ required: { value: true }, min: { value: 8 } }}
                        onChange={e => setNumero(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Date de naissance :{" "}
                    </Label>
                    <Col md={12} className="pr-0">
                      <DatePicker
                          className="form-control ddate"
                          selected={date}
                          onChange={editDate}
                          dateFormat="dd/MM/yyyy"
                      />
                    </Col>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Lieu de naissance :
                    </Label>
                    <Input
                        lg="3"
                        className="form-control"
                        placeholder="Entrer lieu de naissance"
                        type="text"
                        onChange={e => setLieuNaissance(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="d-block mb-3">Etat Civil :</Label>
                    <div className="form-check form-check-inline">
                      <Input
                          type="radio"
                          id="customRadioInline5"
                          name="customRadioInline5"
                          className="form-check-input"
                          checked={etatCivil === 1}
                          onChange={e => setEtatCivil(1)}
                      />
                      <Label
                          className="form-check-label"
                          htmlFor="customRadioInline5"
                      >
                        Célibataire
                      </Label>
                    </div>
                    &nbsp;
                    <div className="form-check form-check-inline">
                      <Input
                          type="radio"
                          id="customRadioInline6"
                          name="customRadioInline5"
                          className="form-check-input"
                          checked={etatCivil === 2}
                          onChange={e => setEtatCivil(2)}
                      />
                      <Label
                          className="form-check-label"
                          htmlFor="customRadioInline6"
                      >
                        Marié(e)
                      </Label>
                    </div>
                    &nbsp;
                    <div className="form-check form-check-inline">
                      <Input
                          type="radio"
                          id="customRadioInline7"
                          name="customRadioInline5"
                          className="form-check-input"
                          checked={etatCivil === 3}
                          onChange={e => setEtatCivil(3)}
                      />
                      <Label
                          className="form-check-label"
                          htmlFor="customRadioInline7"
                      >
                        Divorcé(e)
                      </Label>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Photo :</Label>
                    <Input
                        onChange={e => addFile(e)}
                        className="form-control"
                        type="file"
                        id="formFile"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Rue : </Label>
                    <Input
                        lg="3"
                        className="form-control"
                        placeholder="Entrer rue"
                        type="text"
                        onChange={e => setRue(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Code Postal :</Label>
                    <Input
                        lg="3"
                        className="form-control"
                        placeholder="Entrer code postal"
                        type="number"
                        onChange={e => setCodePostal(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Pays : </Label>
                    <Input
                        lg="3"
                        className="form-control"
                        placeholder="Entrer pays"
                        type="text"
                        onChange={e => setPays(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Gouvernorat :</Label>
                    <Input
                        lg="3"
                        className="form-control"
                        placeholder="Entrer gouvernorat"
                        type="text"
                        onChange={e => setGouvernorat(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <AvField
                        name="email"
                        label="E-Mail"
                        placeholder="Entrer un email valide"
                        type="email"
                        errorMessage="Email invalide"
                        onChange={e => setEmail(e.target.value)}
                        validate={{
                          // required: { value: true },
                          email: { value: true },
                        }}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Téléphone : </Label>
                    <AvField
                        name="tel"
                        placeholder="Entrer téléphone"
                        type="text"
                        errorMessage="* Téléphone obligatoire"
                        className="form-control"
                        validate={{  min: { value: 8 } }}
                        onChange={e => setTel(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Grade : </Label>
                    <Select
                        isSearchable={true}
                        options={grade}
                        onChange={e => setSelectGrade(e)}
                        validate={{required: { value: true }}}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Département : </Label>
                    <Select
                        isSearchable={true}
                        options={departement}
                        onChange={e => setSelectDepartement(e)}
                        validate={{required: { value: true }}}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Statut : </Label>
                    <Select
                        isSearchable={true}
                        options={statut}
                        onChange={e => setSelectStatut(e)}
                        validate={{required: { value: true }}}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Structure de recherche (Unité de recherche ou Laboratoire de
                      recherche) :{" "}
                    </Label>
                    <Input
                        lg="3"
                        className="form-control"
                        placeholder="Entrer Structure de recherche"
                        type="text"
                        onChange={e => setStructure(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">{"Type d'instrument 1 : ( Optionnel )"}</Label>
                    <Select
                        options={typeInstrument}
                        isSearchable={true}
                        value={selectTypeInstrument}
                        onChange={e => setSelectTypeInstrument(e)}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">{"Type d'instrument 2 : ( Spécialité )"}</Label>
                    <Select
                        options={typeInstrument1}
                        isSearchable={true}
                        value={selectTypeInstrument1}
                        onChange={e => setSelectTypeInstrument1(e)}
                    />
                  </div>
                </Col>
              </Row>

            </Form>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Col lg="6">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    className="text-center mt-4"
                >
                  <Button
                      type="button"
                      color="warning"
                      className="btn btn-warning  mb-2 me-2"
                      onClick={props.back}
                  >
                    Annuler
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    className="text-center mt-4"
                >
                  <button
                      disabled={!disbutReg}
                      type="submit"
                      className="btn btn-primary "
                      onClick={save}
                  >
                    CONFIRMER
                  </button>
                </div>
              </Col>
            </div>
          </AvForm>
        </Row>
        <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
        />
      </React.Fragment>
  )
}

export default withRouter(AddEnseignant)
AddEnseignant.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
