import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {Row, Col, Spinner, CardTitle, Form, Label} from "reactstrap"
import { withRouter } from "react-router"
import { ToastContainer, toast, Slide } from "react-toastify"
import DatePicker from "react-datepicker";
import Select from "react-select";
import APIS from "../../api";

const DataTableEns = props => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [departement, setDepartement] = useState([]);
  const [selectDepartement, setSelectDepartement] = useState("");
  const [annee, setAnnee] = useState("");
  const [imp, setImp] = useState(0);
  const [view, setView] = useState(0);
  const [add, setAdd] = useState(0);
  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Emploi enseignant")
    );
    setImp(typeArticleSubMenu.sous_menu[0].imp)
    setView(typeArticleSubMenu.sous_menu[0].view)
    setAdd(typeArticleSubMenu.sous_menu[0].add)
    API.get("departement/select").then(resDepartement => {
      setDepartement(resDepartement.data.Departement)
      setSelectDepartement("")
    })
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    setAnnee(annee_id)
    API.post("emploi/enseignant/list", {
      semestre_id: props.semestre,
      annee_id:annee_id
    }).then(res => {
      setOrders(res.data.enseignants)
      setLoading(true)
    })
  }, [props.semestre])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    sizePerPage: 50,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "name",
      text: "Enseignant",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "cin",
      text: "CIN",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "grade",
      text: "Grade",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "notif",
      isDummyField: true,
      text: "Notifier",
      sort: true,
      style: {textAlign: "center"},
      headerStyle: (colum, colIndex) => {
        return {textAlign: "center"}
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          add === 1 ? (
              <i style={{color: "#b7872f", cursor: "pointer"}}
                 className="fas fa-mail-bulk"
                 onClick={() => download(row)}
              ></i>
          ) : (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#a7a3a3", cursor: "pointer"}}
                    className="fas fa-mail-bulk"
                ></i>
              </div>
          )
      ),
    },
    {
      dataField: "Consultation",
      isDummyField: true,
      text: "Consultation",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          view === 1 ? (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{
                      color: row.emploi == 0 ? "cornflowerblue" : "green",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                        props.history.push(
                            "/EmploiEnseignant?id=" + row.id + "/" + row.semestre
                        )
                    }
                    className="fas fa-eye"
                ></i>
              </div>
          ) : (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#a7a3a3", cursor: "pointer"}}
                    className="fas fa-eye"
                ></i>
              </div>
          )
      ),
    },
    {
      dataField: "Emploi",
      isDummyField: true,
      text: "Emploi",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          add === 1 ? (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{
                      color: row.emploi == 0 ? "cornflowerblue" : "green",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                        props.history.push(
                            "/AddScheduleEnseignant?id=" + row.id + "/" + row.semestre
                        )
                    }
                    className="fas fa-calendar-alt"
                ></i>
              </div>
          ) : (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#a7a3a3", cursor: "pointer"}}
                    className="fas fa-calendar-alt"
                ></i>
              </div>
          )
      ),
    },
    {
      dataField: "id",
      isDummyField: true,
      text: "Téléchargement",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          imp === 1 ? (
              <a
                  href={
                      "https://ismsf.scolarite.backcresus-institut.ovh/api/impression_enseignant_shedules/" +
                      row.id +
                      "/" +
                      row.semestre + "/" + annee
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{display: "flex", justifyContent: "center"}}
              >
                <i
                    style={{color: "#955cd5", cursor: "pointer"}}
                    className="fas fa-file-download"
                ></i>
              </a>
          ) : (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i
                    style={{color: "#a7a3a3", cursor: "pointer"}}
                    className="fas fa-file-download"
                ></i>
              </div>
          )
      ),
    },
  ]
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  const download = (row) => {
    API.post("emploi/envoi_emploi_ensei",
        {
          annee_id,
          enseignant_id:row.id,
          semestre_id: row.semestre,
        }
    ).then(res => {
      toast.success("✔️E-mail enseignant envoyée avec succès !", {
        containerId: "A",
      })
    });
  }
  const { SearchBar } = Search
  const search = async () => {
    if (selectDepartement.value != "" || selectDepartement.value != null) {
      setOrders([])
      const res = await API.post("emploi/enseignant/search", {
        semestre_id: props.semestre,
        departement_id:selectDepartement.value
      }).then(res => {
        setOrders(res.data.enseignants)
      })
    }else{
      toast.error("⛔ département est obligatroire !", {
        containerId: "A",
      })
    }
  }
  const searchAll = async () => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const res = await API.post("emploi/enseignant/list", {
      semestre_id: props.semestre,
      annee_id:annee_id
    }).then(res => {
      setOrders(res.data.enseignants)
      setLoading(true)
    })
  }
  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row className="mt-4">
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <div>
                      <CardTitle style={{ color: "#556ee6" }} className="h4">
                        Critéres de Recherches
                      </CardTitle>
                      <Form className="mt-4">
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input2">Département :</Label>
                              <Select
                                  options={departement}
                                  isSearchable={true}
                                  // isClearable={true}
                                  value={selectDepartement}
                                  onChange={e => setSelectDepartement(e)}
                              />
                            </div>
                          </Col>


                        <Col lg="6" style={{padding: "26px 35px"}}>
                            <button
                                type="button"
                                style={{
                                  backgroundColor: "#761C19",
                                  borderColor: "#761C19",
                                }}
                                className="btn btn-success mb-2 me-2"
                                onClick={search}
                            >
                              Rechercher
                            </button>
                            <button
                                type="button"
                                style={{
                                  backgroundColor: "#761C19",
                                  borderColor: "#761C19",
                                }}
                                className="btn btn-success mb-2 me-2"
                                onClick={searchAll}
                            >
                              Afficher tout
                            </button>

                        </Col>

                        </Row>
                      </Form>

                      <div className="mb-12 row" style={{ display: "flex" }}>
                        <div className="col-md-6 mb-3 row">
                          <div className="col-md-12 search-box-etd">
                            <div
                              className="search-box me-2 mb-2 d-inline-block"
                              style={{ width: "100%" }}
                            >
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div>
                        </div>
                          {imp === 1 ?(
                              <div
                                  style={{
                                      // display: "flex",
                                      // justifyContent: "flex-end",
                                  }}
                                  className="col-md-6 mb-3 row"
                              >
                                  <div
                                      style={{ textAlign: "right" ,display:'contents'}}
                                      className="col-md-6 select-action"
                                  >
                                      {
                                          selectDepartement.value ?(
                                              <a className="col-md-6"
                                                 href={
                                                     "https://ismsf.scolarite.backcresus-institut.ovh/api/impression_enseignants_departement_shedules" + "/" +selectDepartement.value +"/"+
                                                     props.semestre+"/"+annee
                                                 }
                                                 target="_blank"
                                                 rel="noreferrer"
                                              >
                                                  <button className="btn btn-primary btn btn-primary">
                                                      <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                                      Emplois enseignants par département
                                                  </button>
                                              </a>
                                          ):(
                                              <a
                                                  className="col-md-6"
                                                  target="_blank"
                                                  rel="noreferrer"
                                              >
                                                  <button className="btn btn-primary btn btn-primary">
                                                      <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                                      Emplois enseignants par département
                                                  </button>
                                              </a>
                                          )}

                                      <a style={{margin:"0px 10px"}}
                                         href={
                                             "https://ismsf.scolarite.backcresus-institut.ovh/api/impression_enseignants_shedules" + "/" +
                                             props.semestre+"/"+annee
                                         }
                                         target="_blank"
                                         rel="noreferrer"
                                      >
                                          <button className="btn btn-primary btn btn-primary">
                                              <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                              Emplois enseignants
                                          </button>
                                      </a>


                                  </div>
                              </div>
                          ):(null)}

                      </div>
                    </div>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              En cours ...
            </h4>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(DataTableEns)
DataTableEns.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
  semestre: PropTypes.any,
}
