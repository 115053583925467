import React, {Fragment, useEffect, useState} from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import AddEnseignant from "./AddEnseignant"
import DataTableEnseignant from "./DataTableEnseignant"
import ExportEnsiegnant from "./ExportEnsiegnant"
import ImportEnseignant from "./ImportEnseignant"
import FicheVoeauxList from "./FicheVoeauxList";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

const Enseignant = props => {
  const [section, setSection] = useState(1)
  const [add, setAdd] = useState(0);
  const [excel, setExcel] = useState(0);
  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Enseignant")
    );
    setAdd(typeArticleSubMenu.sous_menu[0].add)
    setExcel(typeArticleSubMenu.sous_menu[0].exel)
  }, [])
  const back = () => {
    setSection(1)
  }

  const renderBlock = section => {
    if (section === 1) {
      return <DataTableEnseignant/>
    } else if (section === 2) {
      return <AddEnseignant setSection={setSection} back={back} />
    } else if (section === 3) {
      return <ExportEnsiegnant/>
    } else if (section === 4) {
      return <ImportEnseignant/>
    } else if (section === 5) {
      return <FicheVoeauxList/>
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Enseignant | Cresus </title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
                >
                  Enseignant
                </NavLink>
              </NavItem>
              {add === 1 ? (
                  <Fragment>
                    <NavItem>
                      <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: section === 2,
                          })}
                          onClick={() => {
                            setSection(2)
                          }}
                      >
                        Ajouter enseignant
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: section === 3,
                          })}
                          onClick={() => {
                            setSection(3)
                          }}
                      >
                        Exportation
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: section === 4,
                          })}
                          onClick={() => {
                            setSection(4)
                          }}
                      >
                        Importation
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: section === 5,
                          })}
                          onClick={() => {
                            setSection(5)
                          }}
                      >
                        Fiche Voeaux
                      </NavLink>
                    </NavItem>
                  </Fragment>
              ) :(null)}

                  { excel === 1 ? (
                  <Fragment>
                    <NavItem>
                      <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: section === 3,
                          })}
                          onClick={() => {
                            setSection(3)
                          }}
                      >
                        Exportation
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: section === 4,
                          })}
                          onClick={() => {
                            setSection(4)
                          }}
                      >
                        Importation
                      </NavLink>
                    </NavItem>
                  </Fragment>
              ):(null)}
            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}


export default withRouter(withTranslation()(Enseignant))
Enseignant.propTypes = {
  t: PropTypes.any,
}
