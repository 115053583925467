import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import API from "../../api"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Card, CardBody, Col, Row, Spinner } from "reactstrap"

const ListExamSemUn = props => {
  const [listExamen, setListExamen] = useState([])
  const [loading, setLoading] = useState(false)
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  const [add, setAdd] = useState(0);
  const [edit, setEdit] = useState(0);
  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Principale")
    );
    setAdd(typeArticleSubMenu.sous_menu[0].add)
    setEdit(typeArticleSubMenu.sous_menu[0].edit)
    setLoading(false)
    const resE = await API.post("examen/final/list", {
      niveau_id: props.niveau.value,
      semestre_id: props.semestre,
      session_id: props.session,
      annee_id
    }).then(resE => {
      setListExamen(resE.data.Examen)
      setLoading(true)
    })
  }, [props.niveau, props.semestre, props.session])

  return (
    <React.Fragment>
      {loading ? (
          add === 1 || edit === 1 ? (
              <Row className="mt-4">
                {listExamen.length != 0 ? (
                    listExamen.map((element, key) => (
                        <Col
                            onClick={() =>
                                props.history.push({
                                  pathname: "/ListMatieresNote",
                                  state: {
                                    element: element,
                                    semestre_id: props.semestre,
                                    session_id: props.session,
                                    niveau_id: props.niveau.value,
                                  },
                                })
                            }
                            xl="3"
                            sm="4"
                            key={"_col_" + key}
                        >
                          <Card outline color="primary" className="border border-primary">
                            <CardBody style={{cursor: "pointer"}}>
                              <div className="mt-2 text-center cursor-pointer">
                                <h5>{element.cycle}</h5>
                              </div>
                              <div className="mt-4 text-center">
                                <h5 style={{color: "#556ee6"}}>{element.specialite}</h5>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                    ))
                ) : (
                    <Card>
                      <CardBody>
                        <h4 className="text-center mt-4">
                          Aucun examen pour le moment 😉
                        </h4>
                      </CardBody>
                    </Card>
                )}
              </Row>
          ) : (
              <Card>
                <CardBody>
                  <h4 className="text-center mt-4">
                    {"Vous n'avez pas l'accès 😉"}
                  </h4>
                </CardBody>
              </Card>
          )
      ) : (
          <div className="mt-4">
            <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary"/>
            </div>
            <h4 style={{textAlign: "center", marginTop: "2%"}} className="ms-6">
              {" "}
              {"Chargement"} ...{" "}
            </h4>
          </div>
      )}
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(ListExamSemUn))
ListExamSemUn.propTypes = {
  history: PropTypes.object,
  niveau: PropTypes.object,
  semestre: PropTypes.object,
  session: PropTypes.object,
  t: PropTypes.any,
}
