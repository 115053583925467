import React, { useEffect, useState } from "react"
import Select from "react-select"
import API from "../../api"
import PropTypes from "prop-types"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withTranslation } from "react-i18next"
import {Button, Card, CardBody, Col, Container, Label, Row} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import DataTable from "react-data-table-component"
// Checkbox
import Checkbox from "rc-checkbox"
import "rc-checkbox/assets/index.css"

const ModifUser = props => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [listmenu, setListmenu] = useState([])
    const [id, setId] = useState("")
    const [permissions, setPermissions] = useState([]);

    useEffect(async () => {
        var url = window.location.href
        var array = url.split("=")
        var id = array[1]
        setId(id)
        API.post("scolarite/user/getById", {id}).then(resU => {
            setListmenu(resU.data.Users.permission);
            setName(resU.data.Users.name);
            setEmail(resU.data.Users.email);
        })

    }, [])

    const addPerm = (menu,index, i, permissionType, event) => {
        const checked = event.target.checked;
        const updatedPermissions = [...permissions];
        updatedPermissions[index] = updatedPermissions[index] || [];
        updatedPermissions[index][i] = updatedPermissions[index][i] || {};
        updatedPermissions[index][i][permissionType] = checked ? 1 : 0;
        updatedPermissions[index][i]["sous_menu_id"] = menu;
        listmenu[index]["sous_menu"][i][permissionType]= checked ? 1 : 0;
        setPermissions(updatedPermissions);
    };
    const columns1 = [
        { name: 'Sous-menu', selector: (row) => row.sousmenu, sortable: true},
        { name: 'Ajouter', selector: (row) => row.ajouter, sortable: true },
        { name: 'Supprimer', selector: (row) => row.supprimer, sortable: true },
        { name: 'Modifier', selector: (row) => row.modifier, sortable: true },
        { name: 'Consulter', selector: (row) => row.consulter, sortable: true },
        { name: 'Imprimer', selector: (row) => row.imprimer, sortable: true },
        { name: 'Excel', selector: (row) => row.excel, sortable: true },
    ];
    const dataa = listmenu.map((menu, index) => ({
        id: menu.id,
        name: menu.menu,
        Detail: menu.sous_menu.map((sousmenu, i) => ({
            id: sousmenu.id,
            sousmenu: sousmenu.sous_menu,
            ajouter: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                        checked={permissions[index]?.[i]?.add === 1 || sousmenu.add === 1 }
                        onChange={(e) => addPerm(sousmenu.sous_menu_id,index, i, 'add', e)}
                    />
                </div>
            ),
            supprimer: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                        checked={permissions[index]?.[i]?.delete === 1 || sousmenu.delete === 1 }
                        onChange={(e) => addPerm(sousmenu.sous_menu_id,index, i, 'delete', e)}
                    />
                </div>
            ),
            modifier: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                        checked={permissions[index]?.[i]?.edit  === 1 || sousmenu.edit === 1}
                        onChange={(e) => addPerm(sousmenu.sous_menu_id,index, i, 'edit', e)}
                    />
                </div>
            ),
            consulter: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                        checked={permissions[index]?.[i]?.view === 1 || sousmenu.view === 1}
                        onChange={(e) => addPerm(sousmenu.sous_menu_id,index, i, 'view', e)}
                    />
                </div>
            ),
            imprimer: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                        checked={permissions[index]?.[i]?.imp === 1 || sousmenu.imp === 1}
                        onChange={(e) => addPerm(sousmenu.sous_menu_id,index, i, 'imp', e)}
                    />
                </div>
            ),
            excel: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                        checked={permissions[index]?.[i]?.exel === 1 || sousmenu.exel === 1}
                        onChange={(e) => addPerm(sousmenu.sous_menu_id,index, i, 'exel', e)}
                    />
                </div>
            ),
        })),
    }));
    const edit = async () => {
        const listOfPermissions =permissions
        const filteredPermissions = listOfPermissions.filter(Boolean);
            const res = await API.post("scolarite/user/update", {
                id: id,
                name: name,
                email: email,
                password: password,
                permissions: filteredPermissions,
            }).then(res => {
                props.history.push("/User")
            })

    }

  return (
     <React.Fragment>
      <div className="page-content">
          <Container fluid>
              <Row>
                  <Card>
                      <CardBody>
                          <Row>
                              <AvForm>
                                  <Row>
                                      <Col lg="6">
                                          <div className="mb-3">
                                              <Label for="basicpill-firstname-input1">Nom</Label>
                                              <AvField
                                                  name="nom"
                                                  placeholder="Inserer un nom"
                                                  type="text"
                                                  errorMessage="* Nom obligatoire"
                                                  className="form-control"
                                                  value={name}
                                                  onChange={e => setName(e.target.value)}
                                                  validate={{required: {value: true}}}
                                              />
                                          </div>
                                      </Col>
                                      <Col lg="6">
                                          <Label for="basicpill-firstname-input1">Email</Label>
                                          <AvField
                                              name="email"
                                              value={email}
                                              placeholder="Enter un email valide"
                                              type="email"
                                              onChange={e => setEmail(e.target.value)}
                                              errorMessage="* Email invalide"
                                              validate={{
                                                  required: {value: true},
                                                  email: {value: true},
                                              }}
                                          />
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col lg="6">
                                          <Label for="basicpill-firstname-input1">Password</Label>
                                          <AvField
                                              name="password"
                                              type="password"
                                              placeholder="Inserer un password"
                                              // errorMessage="* Password obligatoire"
                                              // validate={{ required: { value: true } }}
                                              onChange={e => setPassword(e.target.value)}
                                          />
                                      </Col>

                                  </Row>
                                  <Row style={{marginTop: "30px"}}>
                                      {dataa.map((item, index) => (
                                          <div key={index}>
                                              <h3 style={{color: "#c09548"}}>{item.name}</h3>
                                              <DataTable
                                                  columns={columns1}
                                                  data={item.Detail}
                                                  noHeader
                                              />
                                          </div>
                                      ))}
                                  </Row>
                                  <div style={{display: "flex", justifyContent: "center"}}>
                                      {" "}
                                      <Col lg="6">
                                          <div
                                              style={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                              }}
                                              className="text-center mt-4"
                                          >
                                              <Button
                                                  type="button"
                                                  color="warning"
                                                  className="btn btn-warning  mb-2 me-2"
                                                  // onClick={props.back}
                                                  onClick={() => props.history.push("/User")}
                                              >
                                                  {props.t("Annuler")}
                                              </Button>
                                          </div>
                                      </Col>
                                      <Col lg="6">
                                          <div
                                              style={{
                                                  display: "flex",
                                                  justifyContent: "flex-start",
                                              }}
                                              className="text-center mt-4"
                                          >
                                              <button
                                                  onClick={edit}
                                                  type="submit"
                                                  className="btn btn-primary "
                                              >
                                                  Confirmer
                                              </button>
                                          </div>
                                      </Col>
                                  </div>
                              </AvForm>
                          </Row>
                      </CardBody>
                  </Card>
              </Row>
          </Container>
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withTranslation()(ModifUser)
ModifUser.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
