import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
    Row,
    Col,
    Spinner,
    Button,
    Input,
    ModalHeader,
    Modal,
    ModalBody,
    Form,
    Label,
} from "reactstrap"
import { withRouter } from "react-router"
import Select from "react-select"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Switch from "react-switch"
//
const DataTableFicheRenseignement = props => {
    const [loading, setLoading] = useState(false)
    const [arraySelect, setArraySelect] = useState([])
    const [arraySelectAll, setArraySelectAll] = useState([])
    const [orders, setOrders] = useState([])
    const [view, setView] = useState(0);
    const [delet, setDelet] = useState(0);
    const [add, setAdd] = useState(0);
    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Next",
        prePageText: "Back",
        sizePerPage: 30,
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "5",
                value: 5,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "30",
                value: 30,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }

    const NoDataIndication = () => (
        <h6 style={{ textAlign: "center" }}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "cin",
            text: "CIN",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "nom_ar",
            text: "Nom et prénom",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },

        {
            dataField: "telephone",
            text: "Téléphone",
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "email",
            text: "E-mail",
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "Consultation",
            text: "Consultation",
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                view == 1 ? (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Link to={"/DetailEtudiantRenseignement?id=" + row.cin}>
                            <i
                                style={{color: "mediumslateblue", cursor: "pointer"}}
                                className="fas fa-eye"
                            ></i>
                        </Link>
                    </div>
                ) : (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#a7a3a3", cursor: "pointer"}}
                            className="fas fa-eye"
                        ></i>
                    </div>
                )
            ),
            center: true,
            reorder: true,
        },

    ]

    const { SearchBar } = Search

    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    useEffect(async () => {
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Etudiant")
        );
        setView(typeArticleSubMenu.sous_menu[0].view)
        setDelet(typeArticleSubMenu.sous_menu[0].delete)
        setAdd(typeArticleSubMenu.sous_menu[0].add)
        const resE = await API.post("renseignement/get_by_annee",{annee_id}).then(resE => {
            setOrders(resE.data.Renseignement)
            setLoading(true)
        })
    }, [])





    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row className="mt-4">
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <div>
                                            <div className="mb-3 row" style={{ display: "flex" }}>

                                                <div className="col-md-6 mb-3 row">
                                                    <div className="col-md-8 search-box-etd">
                                                        <div
                                                            className="search-box me-2 mb-2 d-inline-block"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <div className="position-relative">
                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication />}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Spinner type="grow" className="ms-6" color="primary" />
                        </div>
                        <h4
                            style={{ textAlign: "center", marginTop: "2%" }}
                            className="ms-6"
                        >
                            {" "}
                            En cours ...
                        </h4>
                    </div>
                )}
            </div>

            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default withRouter(DataTableFicheRenseignement)
DataTableFicheRenseignement.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
}
