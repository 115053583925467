import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Row, Col, Spinner, CardTitle, Form, Label, Table, Button} from "reactstrap"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import Select from "react-select";
import API from "../../api";
const DataTableRegistre = props => {
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  var user_id = userAuthScolarite.user.id
  const [disbutReg, setDisbutReg] = useState(true)
  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState([])
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [loading, setLoading] = useState(true)
  const [date, setDate] = useState([]);
  const [selectDate, setSelectDate] = useState("");
  const [etudiantList, setEtudiantList] = useState([])
  const [enseignant, setEnseignant] = useState([]);
  const [selectEnseignant, setSelectEnseignant] = useState("");
  const [edit, setEdit] = useState(0);
  const [imp, setImp] = useState(0);
  useEffect(async () => {
    const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
    const typeArticleSubMenu = userAuth.find(user =>
        user.sous_menu.some(subMenu => subMenu.sous_menu === "Registre")
    );
    setEdit(typeArticleSubMenu.sous_menu[0].edit)
    setImp(typeArticleSubMenu.sous_menu[0].imp)
    const resE = await API.get("enseignant/select").then(resE => {
      setEnseignant(resE.data.select_enseignant)
    })
  }, [])
  const getMatiere = async (e) => {
    setSelectType(e)
    setSelectMatiere("")
    setSelectGroupe("")
    setEtudiantList([])
    setSelectDate("")
    const res = await API.post("emploi/get_matiere", {
      type: e.value,
      semestre_id: selectSemestre.value,
      enseignant_id: selectEnseignant.value,
      annee_id:annee_id
    }).then(res => {
      setMatiere(res.data.matieres)
    })
  }
  const getType = async (e) => {
    setSelectSemestre(e)
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
    setEtudiantList([])
    setSelectDate("")
    const res = await API.post("emploi/get_type_matiere", {
      annee_id:annee_id,
      enseignant_id: selectEnseignant.value,
      semestre_id: e.value,
    }).then(res => {
      setType(res.data.emploi)
    })
  }
  const getGroup = async (e) => {
    setSelectMatiere(e)
    setSelectGroupe("")
    setEtudiantList([])
    setSelectDate("")
    const res = await API.post("registre/get_group_by_enseignant_id", {
      enseignant_id: selectEnseignant.value,
      matiere_id:e.value,
      type_matiere:selectType.value,
      annee_id:annee_id,
      semestre_id: selectSemestre.value,
    }).then(res => {
      setGroupe(res.data.Group)
    })
  }
  const getDate = async (e) => {
    setSelectGroupe(e)
    setSelectDate("")
    setEtudiantList([])
    const res = await API.post("registre/get_date", {
      enseignant_id: selectEnseignant.value,
      matiere_id:selectMatiere.value,
      type_matiere:selectType.value,
      annee_id:annee_id,
      group_id:e.value
    }).then(res => {
      setDate(res.data.Date)
    })
  }
  const getList = async (e) => {
    setSelectDate(e)
    API.post("registre/get_etudiant", {
      enseignant_id: selectEnseignant.value,
      matiere_id:selectMatiere.value,
      type_matiere:selectType.value,
      semestre_id:selectSemestre.value,
      annee_id:annee_id,
      group_id:selectGroupe.value,
      date:e.label
    }).then(res => {
      setEtudiantList(res.data.Etudiant.etudiant)
      setLoading(true)
    })
  }
  const getVider = async (e) => {
    setSelectEnseignant(e)
    setSelectSemestre("")
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
    setSelectDate("")
    setEtudiantList([])
  }
  const changeAbsence = (event, index) => {
    let absence = event.target.checked
    if (absence == 1) {
      setEtudiantList(
          etudiantList.map((el, id) =>
              id === index ? Object.assign(el, { presence: 1}) : el
          )
      )
    } else {
      setEtudiantList(
          etudiantList.map((el, id) =>
              id === index ? Object.assign(el, { presence: 0}) : el
          )
      )
    }
  }
  const update = async () => {
    setDisbutReg(false)
    const res = await API.post("registre/update", {
      annee_id,
      enseignant_id: selectEnseignant.value,
      matiere_id: selectMatiere.value,
      type_matiere: selectType.value,
      semestre_id: selectSemestre.value,
      group_id: selectGroupe.value,
      date:selectDate.label,
      etudiants: etudiantList,
      user_id:user_id
    }).then(res => {
        if(res.data.errNum == 200){
          setSelectEnseignant("")
          setSelectSemestre("")
          setSelectType("")
          setSelectMatiere("")
          setSelectGroupe("")
          setSelectDate("")
          setEtudiantList([])
          setDisbutReg(true)
          setLoading(true)
        }else{
          setDisbutReg(false)
        }
    })
  }
  return (
    <React.Fragment>
      <div>
        <CardTitle style={{ color: "#556ee6" }} className="h4">
          Critéres de Recherches
        </CardTitle>
        <Form className="mt-4">
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Enseignant")}
                </Label>
                <Select
                    options={enseignant}
                    isSearchable={true}
                    onChange={e => getVider(e)}
                    value={selectEnseignant}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Semestre
                </Label>
                <Select
                    options={semestre}
                    isSearchable={true}
                    onChange={e => getType(e)}
                    value={selectSemestre}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Type</Label>
                <Select
                    options={type}
                    isSearchable={true}
                    value={selectType}
                    onChange={e => getMatiere(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Matiere</Label>
                <Select
                    options={matiere}
                    isSearchable={true}
                    value={selectMatiere}
                    onChange={e => getGroup(e)}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Groupe</Label>
                <Select
                    options={groupe}
                    isSearchable={true}
                    value={selectGroupe}
                    onChange={e => getDate(e)}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Date
                </Label>
                <Select
                    options={date}
                    isSearchable={true}
                    onChange={e => getList(e)}
                    value={selectDate}
                />
              </div>
            </Col>
            {
              selectEnseignant.value !== "" &&
              selectSemestre.value !== "" &&
              selectGroupe.value !== "" &&
              selectMatiere.value !== "" &&
              selectType.value !== "" ? (
                  imp === 1 ? (
                      <Col lg="3">
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            className="text-center mt-4"
                        >
                          <a
                              style={{ margin: "0px 10px" }}
                              href={`https://ismsf.scolarite.backcresus-institut.ovh/api/impression_fiche_presence/${annee_id}/${selectEnseignant.value}/${selectGroupe.value}/${selectMatiere.value}/${selectSemestre.value}/${selectType.value}`}
                              target="_blank"
                              rel="noreferrer"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                    e.currentTarget.href,
                                    '_blank'
                                );
                              }}
                          >
                            <button className="btn btn-primary btn btn-secondary">
                              <i className="fas fa-file-pdf font-size-16 align-middle me-2"></i>
                              Imprimer Registre tous les seances
                            </button>
                          </a>
                        </div>
                      </Col>
                  ) : null
              ) : null
            }


          </Row>
        </Form>
        {loading ? (
          <Row>
            <Col xs="12">
              <Table className="mt-3" striped bordered hover>
                <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>CIN</th>
                  <th style={{ textAlign: "center" }}>Etudiant</th>
                  <th style={{ textAlign: "center" }}>Présence</th>
                </tr>
                </thead>
                <tbody>
                {etudiantList.map((el, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}> {el.cin} </td>
                      <td style={{ textAlign: "center" }}> {el.name} </td>
                      <td className="square-switch" style={{ textAlign: "center" }} >
                        <input
                            type="checkbox"
                            id={"square-switch" + index}
                            switch="bool"
                            checked={el.presence}
                            onChange={e => changeAbsence(e, index)}
                        />
                        <label
                            htmlFor={"square-switch" + index}
                            data-on-label="P"
                            data-off-label="A"
                        />
                      </td>
                    </tr>
                ))}
                </tbody>
              </Table>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/*{imp === 1 ? (*/}
                {/*    <Col lg="6">*/}
                {/*      <div*/}
                {/*          style={{*/}
                {/*            display: "flex",*/}
                {/*            justifyContent: "flex-end",*/}
                {/*          }}*/}
                {/*          className="text-center mt-4"*/}
                {/*      >*/}
                {/*        /!*<a style={{margin:"0px 10px"}}*!/*/}
                {/*        /!*   href={*!/*/}
                {/*        /!*       "https://ismsf.scolarite.backcresus-institut.ovh/api/impression_fiche_presence/"+annee_id+"/"+selectDate.label+"/"+selectEnseignant.value+"/"+selectGroupe.value+"/"+selectMatiere.value+"/"+selectSemestre.value+"/"+selectType.value*!/*/}
                {/*        /!*   }*!/*/}
                {/*        /!*   target="_blank"*!/*/}
                {/*        /!*   rel="noreferrer"*!/*/}
                {/*        /!*>*!/*/}
                {/*        /!*  <button className="btn btn-primary btn btn-secondary">*!/*/}
                {/*        /!*    <i className="fas fa-file-pdf font-size-16 align-middle me-2"></i>*!/*/}
                {/*        /!*    Imprimer*!/*/}
                {/*        /!*  </button>*!/*/}
                {/*        /!*</a>*!/*/}
                {/*      </div>*/}
                {/*    </Col>*/}
                {/*):(null)}*/}
                {edit === 1 ?(
                    <Col lg="6">
                      <div className="text-center mt-4">
                        <button
                            disabled={!disbutReg}
                            type="button"
                            className="btn btn-primary "
                            onClick={update}
                        >
                          {props.t("Confirmer")}
                        </button>
                      </div>
                    </Col>
                ):(null)}

              </div>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableRegistre))
DataTableRegistre.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
