import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Container,
  Card,
  CardBody,
  CardTitle,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
//i18n
import { withTranslation } from "react-i18next"
import Select from "react-select"

const EditMatiere = props => {
  const [matiere, setMatiere] = useState("")
  const [coeff, setCoeff] = useState("")
  const [credit, setCredit] = useState("")
  const [nbrCours, setNbrCours] = useState("")
  const [nbrTD, setNbrTD] = useState("")
  const [nbrTP, setNbrTP] = useState("")
  const [typeInstrument, setTypeInstrument] = useState([
    { value: 1, label: "Avec Insctument" },
    { value: 2, label: "Instrument de spécialité " },
    { value: 3, label: "Deuxieme instrument " },
  ]);
  const [modalite, setModalite] = useState([
    { value: 1, label: "Régime mixte" },
    { value: 2, label: "Contrôle continu" },
  ])
  const [typeMatiere, setTypeMatiere] = useState([
    { value: 1, label: "Par groupe" },
    { value: 2, label: "Par étudiant" },
    { value: 3, label: "Par groupe étudiant" },
  ])
  const [selectTypeInstrument, setSelectTypeInstrument] = useState("");
  const [selectTypeMatiere, setSelectTypeMatiere] = useState("");
  const [selectModalite, setSelectModalite] = useState("")
  const [autre, setAutre] = useState("")
  const [departement, setDepartement] = useState([])
  const [selectDepartement, setSelectDepartement] = useState("")
  const [loading, setLoading] = useState(false)
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  //

  useEffect(async () => {
    // Département
    const resD = await API.get("departement/select").then(resD => {
      setDepartement(resD.data.Departement)
    })
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("matiere/get_by_id", { id }).then(res => {
      setMatiere(res.data.Matiere.name)
      setCoeff(res.data.Matiere.coef)
      setCredit(res.data.Matiere.credit)
      setNbrCours(res.data.Matiere.nbhCours)
      setNbrTD(res.data.Matiere.nbhTD)
      setNbrTP(res.data.Matiere.nbhTP)
      setAutre(res.data.Matiere.autre)
      setSelectModalite(res.data.Matiere.modalite)
      setSelectDepartement(res.data.Matiere.departement_id)
      setSelectSemestre(res.data.Matiere.semestre_id)
      setSelectTypeMatiere(res.data.Matiere.par_group)
      setSelectTypeInstrument(res.data.Matiere.type_instrument)
      setLoading(true)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (matiere != "" || selectSemestre!="") {
      const res = await API.post("matiere/update", {
        id: id,
        name: matiere,
        coef: coeff,
        credit: credit,
        nbrhCours: nbrCours,
        nbrhTD: nbrTD,
        nbrhTP: nbrTP,
        autre: autre,
        modalite_id: selectModalite.label,
        departement_id: selectDepartement.value,
        semestre_id:selectSemestre.value,
        par_group:selectTypeMatiere.value,
        type_instrument:selectTypeInstrument.value
      }).then(res => {
        props.history.push("/Matiere")
      })
    } else {
      toast.error("⛔ Nom matière obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Semestre</Label>
                          <Select
                              options={semestre}
                              isSearchable={true}
                              value={selectSemestre}
                              onChange={e => setSelectSemestre(e)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Matière :
                          </Label>
                          <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              placeholder="Matiere"
                              value={matiere}
                              onChange={e => setMatiere(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>

                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Coeff :
                          </Label>
                          <Input
                            lg="3"
                            type="number"
                            className="form-control"
                            placeholder="Coeff"
                            value={coeff}
                            onChange={e => setCoeff(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Crédit :
                          </Label>
                          <Input
                              lg="3"
                              type="number"
                              className="form-control"
                              placeholder="Crédit"
                              value={credit}
                              onChange={e => setCredit(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>

                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre heure cours :
                          </Label>
                          <Input
                            lg="3"
                            type="number"
                            className="form-control"
                            placeholder="Nbr heure cours"
                            value={nbrCours}
                            onChange={e => setNbrCours(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre heure TD :
                          </Label>
                          <Input
                              lg="3"
                              type="number"
                              className="form-control"
                              placeholder="Nbr heure TD"
                              value={nbrTD}
                              onChange={e => setNbrTD(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>

                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre heure TP :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            placeholder="Nbr heure TP"
                            value={nbrTP}
                            onChange={e => setNbrTP(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre heure autre :
                          </Label>
                          <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              placeholder="Nbr heure autre"
                              value={autre}
                              onChange={e => setAutre(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>

                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Modalité :
                          </Label>
                          <Select
                            options={modalite}
                            isSearchable={true}
                            value={selectModalite}
                            onChange={e => setSelectModalite(e)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Departement :
                          </Label>
                          <Select
                              options={departement}
                              isSearchable={true}
                              value={selectDepartement}
                              onChange={e => setSelectDepartement(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Type matière :
                          </Label>
                          <Select
                              options={typeMatiere}
                              isSearchable={true}
                              value={selectTypeMatiere}
                              onChange={e => setSelectTypeMatiere(e)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Type Instrument :
                          </Label>
                          <Select
                              options={typeInstrument}
                              isSearchable={true}
                              value={selectTypeInstrument}
                              onChange={e => setSelectTypeInstrument(e)}
                          />
                        </div>
                      </Col>
                    </Row>

                  </Form>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Matiere")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={edit}
                        >
                          CONFIRMER
                        </button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {"Chargement"} ...{" "}
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditMatiere)
EditMatiere.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
