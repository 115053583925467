import React, { useEffect, useState } from "react"
import Select from "react-select"
import API from "../../api"
import PropTypes from "prop-types"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withTranslation } from "react-i18next"
import { Button, Col, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import DataTable from "react-data-table-component"
// Checkbox
import Checkbox from "rc-checkbox"
import "rc-checkbox/assets/index.css"

const DetailUser = props => {
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [roles, setRoles] = useState([])
    const [selectRole, setSelectRole] = useState("")
  // pagination
  const [nbrTotlEl, setNbrTotlEl] = useState(0)
  const [nbrEltperPage, setNbrEltperPage] = useState(5)
  const [filterElement, setFilterElement] = useState("")
  const [mycurrentPage, setMycurrentPage] = useState(1)
  const [resetFirstPage, setResetFirstPage] = useState(false)
  const [listmenu, setListmenu] = useState([])
  const [id, setId] = useState("")
   //const [data, setData] = useState([])
   const [permissions, setPermissions] = useState([]);

   useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setId(id)
 
   API.post("user/get_by_id", { id }).then(resU => {
  
    setListmenu(resU.data.Users.permission);
    setName(resU.data.Users.name);
    setPassword(resU.data.Users.password)
    setEmail(resU.data.Users.email);
    setRoles(resU.data.Users.role);
    
   
  })
 
}, [])

console.log(listmenu)


const expandableRowsComponent = (row) => (
  <div>
 
    {row.data.Detail.map((el,i)=>(
      <div key={i}>
{el}
      </div>
    ))}
  </div>
);

const dataa = listmenu.map((menu,index) => ({
  id: menu.menu_id,
  name: menu.menu,
 Detail: menu.sous_menu.map((sousmenu, i) => (

<div key={i}>

           <DataTable
      columns={[
        { name: 'sousmenu', selector: 'sousmenu', sortable: true },
        { name: 'ajouter', selector: 'ajouter', sortable: true },
        { name: 'supprimer', selector: 'supprimer', sortable: true },
        { name: 'modifier', selector: 'modifier', sortable: true },
        { name: 'consulter', selector: 'consulter', sortable: true },
        { name: 'imprimer', selector: 'imprimer', sortable: true },

      ]}
      
      data={
       [{
     id: sousmenu.sous_menu_id,
        sousmenu: sousmenu.sous_menu,
        ajouter: (
          <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
          <Checkbox 
            checked={sousmenu.add === 1}
            disabled={sousmenu.add === 0}
        //  onChange={(e) => addPerm(index,e, i, 'add')}
        
        />
          

          </div>
        ),
        supprimer: (
          <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
             <Checkbox 
           checked={sousmenu.delete === 1}
           disabled={sousmenu.delete === 0}
             //onChange={(e) => addPerm(index,e, i, 'delete')}
              />
          </div>
        ),
        modifier: (
          <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
           <Checkbox 
             checked={sousmenu.edit === 1}
             disabled={sousmenu.edit === 0}
           //onChange={(e) => addPerm(index,e, i, 'edit')} 
           />
          </div>
        ),
        consulter: (
          <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
            <Checkbox 
        checked={sousmenu.view === 1}
        disabled={sousmenu.view === 0}
            //onChange={(e) => addPerm(index,e, i, 'view')} 
            />
          </div>
        ),
        imprimer: (
          <div key={i} style={{ display: 'flex', justifyContent: 'center' }}>
            <Checkbox 
              checked={sousmenu.imp === 1}
              disabled={sousmenu.imp === 0}
          //  onChange={(e) => addPerm(index,e, i, 'imp')}
             />
          </div>
        )
       }] 
        
   
     
    
    }
      noHeader
     
    //  expandableRowsComponent={YourComponent}
    />
</div>
  )),
}));

const renderChildRow = (row) => {
  return (
    <div>
      <p>Name: {row.name}</p>
      <p>Age: {row.age}</p>
      <p>Country: {row.country}</p>
      {/* You can add other child row information as needed */}
    </div>
  );
};
const columns = [

  { name: 'Menu', selector: (row) => row.name },

];



const newElement = async (page, totalRows) => {
    setMycurrentPage(page)
    const res = await API.get("menu/list")
    .then(res => {
      setListmenu(res.data.Menu)
      console.log(listmenu)
      //setNbrTotlEl(res.data.Data[0].total)
    })
  }

  const perPage = async (currentRowsPerPage, currentPage) => {
    setMycurrentPage(currentPage)
    const res = await API.get("menu/list")
    .then(res => {
      setListmenu(res.data.Menu)
      // setNbrTotlEl(res.data.Data[0].total)
    })
  }

 return (
    <React.Fragment>
      <div className="page-content card" >
        <Row>
          <AvForm>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Nom</Label>
                  <AvField
                    name="nom"
                    placeholder="Inserer un nom"
                    type="text"
                    disabled
                    errorMessage="* Nom obligatoire"
                    className="form-control"
                    value={name}
                  //  onChange={e => setName(e.target.value)}
                    validate={{ required: { value: true } }}
                  />
                </div>
              </Col>
              <Col lg="6">
                <Label for="basicpill-firstname-input1">Email</Label>
                <AvField
                  name="email"
                  disabled
                  placeholder="Enter un email valide"
                  type="email"
                  value={email}
                //  onChange={e => setEmail(e.target.value)}
                  errorMessage="* Email invalide"
                  validate={{
                    required: { value: true },
                    email: { value: true },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <Label for="basicpill-firstname-input1">Password</Label>
                <AvField
                  name="password"
                  type="password"
                  disabled
                  value={password}
                  placeholder="Inserer un password"
                  errorMessage="* Password obligatoire"
                  validate={{ required: { value: true } }}
                 // onChange={e => setPassword(e.target.value)}
                />
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-address-input1">Rôle</Label>
                  <Select
                  isDisabled
                    value={roles}
                    isSearchable={true}
                    classNamePrefix="select2-selection"
                   // onChange={setSelectRole}
                  />
                </div>
              </Col>
            </Row>
    {/* datatable permissions */}
          <DataTable
      title="Permissions"
      columns={columns}
      data={dataa}
      // pagination={true}
      // paginationServer={true}
      // onChangePage={(page, totalRows) => newElement(page, totalRows)}
      expandableRows
      expandableRowsComponent={expandableRowsComponent}
      //onExpandableRowClicked={expandableRowsComponent}
       onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                perPage(currentRowsPerPage, currentPage)
              }
    />
              <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <Col lg="6">
                        <div className="text-center mt-4">
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() => props.history.push("/User")}
                          >
                            Annuler
                          </Button>
                        </div>
                      </Col>
                    </div>
          </AvForm>
        
       

        </Row>
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

//export default DetailUser
export default withTranslation()(DetailUser)
DetailUser.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
