import React, { useEffect, useState } from "react"
import API from "../../api"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { withTranslation } from "react-i18next"
import { Button, Col, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import DataTable from "react-data-table-component"
import Checkbox from "rc-checkbox"
import "rc-checkbox/assets/index.css"
const AddUser = props => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [listmenu, setListmenu] = useState([]);
    const [permissions, setPermissions] = useState("");
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    useEffect(() => {
        const fetchData = async () => {
            const res = await API.get("scolarite/user/getmenu");
            setListmenu(res.data.Menu);
        };
        fetchData();
    }, []);
    const addPerm = (menu,index, i, permissionType, event) => {
        const checked = event.target.checked;
        const updatedPermissions = [...permissions];
        updatedPermissions[index] = updatedPermissions[index] || [];
        updatedPermissions[index][i] = updatedPermissions[index][i] || {};
        updatedPermissions[index][i][permissionType] = checked ? 1 : 0;
        updatedPermissions[index][i]["sous_menu_id"] = menu;
        setPermissions(updatedPermissions);
    };

    const save = async () => {
        const listOfPermissions =permissions
        const filteredPermissions = listOfPermissions.filter(Boolean);
        if (name !== '' && email !== '' && password !== '' ) {
            const res = await API.post('scolarite/user/store', {
                name,
                email,
                password,
                permissions:filteredPermissions,
                annee_id
            }).then(res => {
                props.setSection(1)
            })
        }
    };
    const columns1 = [
        { name: 'Sous-menu', selector: (row) => row.sousmenu, sortable: true },
        { name: 'Ajouter', selector: (row) => row.ajouter, sortable: true },
        { name: 'Supprimer', selector: (row) => row.supprimer, sortable: true },
        { name: 'Modifier', selector: (row) => row.modifier, sortable: true },
        { name: 'Consulter', selector: (row) => row.consulter, sortable: true },
        { name: 'Imprimer', selector: (row) => row.imprimer, sortable: true },
        { name: 'Excel', selector: (row) => row.excel, sortable: true },
    ];
    const dataa = listmenu.map((menu, index) => ({
        id: menu.id,
        name: menu.name,
        Detail: menu.sous_menu.map((sousmenu, i) => ({
            id: sousmenu.id,
            sousmenu: sousmenu.name,
            ajouter: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                        checked={permissions[index]?.[i]?.add ?? 0 === 1}
                        onChange={(e) => addPerm(sousmenu.id,index, i, 'add', e)}
                    />
                </div>
            ),
            supprimer: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                        checked={permissions[index]?.[i]?.delete ?? 0 === 1}
                        onChange={(e) => addPerm(sousmenu.id,index, i, 'delete', e)}
                    />
                </div>
            ),
            modifier: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                        checked={permissions[index]?.[i]?.edit ?? 0 === 1}
                        onChange={(e) => addPerm(sousmenu.id,index, i, 'edit', e)}
                    />
                </div>
            ),
            consulter: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                        checked={permissions[index]?.[i]?.view ?? 0 === 1}
                        onChange={(e) => addPerm(sousmenu.id,index, i, 'view', e)}
                    />
                </div>
            ),
            imprimer: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                        checked={permissions[index]?.[i]?.imp ?? 0 === 1}
                        onChange={(e) => addPerm(sousmenu.id,index, i, 'imp', e)}
                    />
                </div>
            ),
            excel: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Checkbox
                        checked={permissions[index]?.[i]?.exel ?? 0 === 1}
                        onChange={(e) => addPerm(sousmenu.id,index, i, 'exel', e)}
                    />
                </div>
            ),
        })),
    }));
    return (
        <React.Fragment>
            <div>
                <Row>
                    <AvForm>
                        <Row>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">Nom</Label>
                                    <AvField
                                        name="nom"
                                        placeholder="Insérer un nom"
                                        type="text"
                                        errorMessage="* Nom obligatoire"
                                        className="form-control"
                                        onChange={(e) => setName(e.target.value)}
                                        validate={{ required: { value: true } }}
                                    />
                                </div>
                            </Col>
                            <Col lg="6">
                                <Label for="basicpill-firstname-input1">Email</Label>
                                <AvField
                                    name="email"
                                    value={email}
                                    placeholder="Insérer un email valide"
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    errorMessage="* Email invalide"
                                    validate={{
                                        required: { value: true },
                                        email: { value: true },
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <Label for="basicpill-firstname-input1">Password</Label>
                                <AvField
                                    name="password"
                                    type="password"
                                    placeholder="Insérer un mot de passe"
                                    errorMessage="* Mot de passe obligatoire"
                                    validate={{ required: { value: true } }}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop : "30px"}}>
                            {dataa.map((item, index) => (
                                <div key={index}>
                                    <h3 style={{color : "#c09548"}}>{item.name}</h3>
                                    <DataTable
                                        columns={columns1}
                                        data={item.Detail}
                                        noHeader
                                    />
                                </div>
                            ))}
                        </Row>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {" "}
                            <Col lg="6">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                    className="text-center mt-4"
                                >
                                    <Button
                                        type="button"
                                        color="warning"
                                        className="btn btn-warning  mb-2 me-2"
                                        // onClick={props.back}
                                    >
                                        {props.t("Annuler")}
                                    </Button>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                    className="text-center mt-4"
                                >
                                    <button
                                        onClick={save}
                                        type="submit"
                                        className="btn btn-primary "
                                    >
                                        Confirmer
                                    </button>
                                </div>
                            </Col>
                        </div>
                    </AvForm>
                </Row>
            </div>
        </React.Fragment>
    );

}

export default withTranslation()(AddUser)

AddUser.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
